@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");
.main-layout {
  padding: 30px; }

.no-padding {
  padding: 0; }
  .no-padding .move-left {
    padding-left: 0; }

body {
  font-family: 'Poppins', sans-serif;
  background-color: #DEEEFE;
  overflow-x: hidden; }

input:focus,
select:focus {
  box-shadow: none !important;
  outline: none !important; }

.pl15 {
  padding-left: 30px; }

.checkbox-wrap-v2 {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center; }
  .checkbox-wrap-v2 .checkbox-v2 {
    background-color: #E6EDF5;
    width: 20px;
    height: 20px;
    min-width: 20px;
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px; }
    .checkbox-wrap-v2 .checkbox-v2::after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-bottom: 0 solid #fff;
      border-right: 0 solid #fff;
      transform: rotate(45deg);
      transition: 0.3s all; }
    .checkbox-wrap-v2 .checkbox-v2.checkbox-v2-checked {
      background-color: #138DB9; }
      .checkbox-wrap-v2 .checkbox-v2.checkbox-v2-checked::after {
        content: '';
        display: block;
        width: 6px;
        height: 10px;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(45deg); }
  .checkbox-wrap-v2.checkbox-wrap-v2-disabled .checkbox-v2 {
    background-color: #BCC3CF; }
  .checkbox-wrap-v2.checkbox-wrap-v2-disabled span {
    color: #BCC3CF; }

.side-margin {
  margin: 0 15px; }

.move-left {
  padding-left: 295px; }
  @media only screen and (max-width: 767px) {
    .move-left {
      padding-left: 0;
      padding-top: 50px; } }

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1034px;
  width: 100%;
  height: 90vh;
  background-color: transparent;
  z-index: 99999;
  overflow-y: hidden !important; }

.modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }
  .modal-title > div {
    cursor: pointer;
    font-size: 24px; }

.almbih-content-opened {
  display: block;
  border-radius: 6px; }

.albmih-content .modal-header {
  border-bottom: 0; }
  .albmih-content .modal-header h6 {
    padding-top: 10px;
    font-size: 21px;
    color: #3A416F;
    font-weight: 600;
    text-transform: uppercase; }

.mt-15 {
  margin-top: 15px; }

.button-dark {
  background-color: #3A416F !important; }

.v-scroll {
  max-height: 440px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden; }

.v-scroll::-webkit-scrollbar {
  width: 10px; }

.v-scroll::-webkit-scrollbar-thumb {
  background-color: #818FA5;
  border-radius: 6px; }

.flex-end {
  display: flex;
  justify-content: flex-end; }

.last-change-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px; }
  .last-change-footer .last-change {
    font-size: 14px;
    margin-top: 20px;
    color: #818FA5; }
    .last-change-footer .last-change > span {
      font-weight: 600;
      color: #818FA5; }
    .last-change-footer .last-change:hover {
      cursor: pointer;
      opacity: 0.94;
      transition: 0.2s ease-in; }
  .last-change-footer .almbih {
    width: 110px;
    height: 50px;
    background-color: #3A416F;
    border-radius: 6px;
    line-height: 35px;
    text-align: center;
    font-size: 13px;
    text-transform: uppercase;
    color: #fff;
    outline: none;
    border: none; }
    .last-change-footer .almbih:hover {
      opacity: 0.9;
      transition: 0.2s ease-in; }

.list-toggle-custom {
  position: absolute;
  left: -150px;
  min-width: 300px;
  z-index: 3; }

.list-toggle-custom-expand {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  left: 30px; }
  .list-toggle-custom-expand .toogle-switch {
    top: 0 !important;
    left: -10px !important; }
  .list-toggle-custom-expand button {
    left: -15px; }

.list-buttons-custom {
  width: 460px;
  min-width: 100%;
  z-index: 3; }

.list-buttons-custom-sm {
  width: 254px;
  min-width: 100%;
  z-index: 3; }

.list-buttons-custom-supplier-return {
  width: 360px;
  min-width: 100%;
  z-index: 3; }

.lock-icon-container {
  background-color: transparent;
  outline: none;
  box-shadow: none;
  border: none; }
  .lock-icon-container:focus, .lock-icon-container:active, .lock-icon-container:hover {
    outline: none !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important; }

.lock-icon {
  position: relative;
  top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
  background-color: none; }

.modal-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  max-width: 100%;
  width: 500px; }

.modal-body {
  text-align: center; }
  .modal-body img {
    margin-bottom: 15px; }
  .modal-body .message {
    font-size: 21px;
    font-weight: 600; }

.modal-footer {
  justify-content: center;
  border-top: 0; }
  .modal-footer button {
    width: 100px;
    background-color: #138DB9 !important;
    border: none; }

.modal-clasic {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1034px;
  z-index: 997;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }

.overlay-whole {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(42, 47, 53, 0.6);
  z-index: 991;
  display: none; }

.shown {
  display: block; }

.special-dropdown {
  position: relative; }
  .special-dropdown ul {
    position: absolute;
    top: 35px;
    right: 0;
    display: inline-block;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    padding-left: 0;
    padding: 15px 20px;
    z-index: 991;
    display: none; }
    .special-dropdown ul li {
      list-style: none;
      position: relative;
      font-size: 15px;
      color: #3A416F;
      padding-bottom: 5px; }
      .special-dropdown ul li:hover {
        cursor: pointer; }
      .special-dropdown ul li:last-child {
        padding-bottom: 0; }

.opened ul {
  display: block; }

.invalid-tooltip {
  display: block;
  top: 50%;
  left: unset;
  right: -150px;
  background-color: #2A2F35;
  font-size: 11px;
  height: 24px;
  max-width: unset; }
  .invalid-tooltip:before {
    content: '';
    display: block;
    position: absolute;
    top: 6.5px;
    left: -6px;
    width: 12px;
    height: 12px;
    background-color: #2A2F35;
    transform: rotate(46deg); }

.api-method-POST {
  color: orange;
  min-width: 50px;
  display: inline-block;
  font-weight: 600; }

.api-method-GET {
  color: green;
  min-width: 50px;
  display: inline-block;
  font-weight: 600; }

.api-method-PUT {
  color: purple;
  min-width: 50px;
  display: inline-block;
  font-weight: 600; }

.api-method-DELETE {
  color: red;
  min-width: 50px;
  display: inline-block;
  font-weight: 600; }

.toogle-switch {
  display: flex;
  align-items: center;
  padding: 10px 0; }
  .toogle-switch > div {
    cursor: pointer;
    margin-right: 10px;
    background-color: #D2D6DA;
    height: 20px;
    border-radius: 87px;
    width: 40px;
    min-width: 40px;
    padding: 2px;
    position: relative;
    transition: all 0.3s; }
    .toogle-switch > div > div {
      width: 16px;
      height: 16px;
      background-color: #fff;
      border-radius: 8px;
      position: absolute;
      top: 2px;
      left: 2px;
      transition: all 0.3s; }
  .toogle-switch.toogle-switch-active > div {
    background-color: #138DB9; }
    .toogle-switch.toogle-switch-active > div > div {
      left: 22px; }
  .toogle-switch > span {
    font-size: 14px;
    color: #3A3F56; }

.col-up {
  margin-top: -82px; }

.col-up-right {
  z-index: 223; }

.col-up-right-top {
  position: relative;
  right: -15px;
  margin-top: -102px; }

.col-up-right-top-max {
  margin-top: -168px; }

.col-up-right-top-max-2 {
  margin-top: -210px; }

.col-up-right-top-max-3 {
  margin-top: -172px; }

.buttons-position {
  display: flex;
  justify-content: flex-end;
  position: relative;
  right: 20px; }
  .buttons-position .option-header {
    width: 450px; }

.select-field {
  position: relative; }
  .select-field > svg {
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 11px; }
  .select-field select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: ''; }

.field-strap-wrap select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  background-image: url("./assets/arrow-down.svg");
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 15px; }

.react-tagsinput {
  border-radius: 6px;
  border: 1px solid #D2D6DA;
  padding-top: 0 !important;
  height: 42px;
  line-height: 30px;
  padding-left: 5px !important; }
  .react-tagsinput.react-tagsinput--focused {
    border: 1px solid #D2D6DA; }
  .react-tagsinput .react-tagsinput-tag {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background-color: #138DB9;
    color: #fff;
    border: 0;
    border-radius: 3px;
    padding: 7px 11px;
    height: 35px;
    margin-top: 1px; }
    .react-tagsinput .react-tagsinput-tag .react-tagsinput-remove {
      color: #fff; }
  .react-tagsinput .react-tagsinput-input {
    margin-bottom: 0 !important;
    border: 0 !important;
    height: unset !important; }

.list-field {
  border: 1px solid #D2D6DA;
  border-radius: 6px;
  padding: 15px 10px 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .list-field .items {
    overflow-y: auto;
    margin-bottom: 10px;
    padding-right: 10px; }
    .list-field .items > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      color: #3A3F56;
      margin-bottom: 13px; }
      .list-field .items > div button {
        background-color: transparent;
        border: 0;
        outline: none; }
  .list-field .input {
    position: relative;
    border: 1px dashed #D2D6DA;
    border-radius: 6px;
    margin-right: 10px; }
    .list-field .input input {
      border: 0 !important;
      outline: none;
      margin-bottom: 0 !important;
      background-color: #F8F9FA !important; }
      .list-field .input input:focus {
        background-color: #fff !important; }
    .list-field .input svg {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -5px;
      margin-left: -5px;
      cursor: pointer;
      pointer-events: none; }

.list-builder-no-buttons {
  position: relative;
  top: -71.2px; }

/*========== LIST BUILDER ==========*/
.table-tabs {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  position: relative;
  z-index: 1; }
  @media only screen and (max-width: 767px) {
    .table-tabs {
      display: flex; } }
  .table-tabs button {
    min-width: 205px;
    height: 40px;
    border-radius: 6px;
    font-size: 15px;
    color: #3A416F;
    border: none;
    outline: none;
    background: transparent; }
    @media only screen and (max-width: 767px) {
      .table-tabs button {
        font-size: 13px; } }
    .table-tabs button:nth-child(2) {
      margin: 0 15px; }
    .table-tabs button:hover {
      background-color: #fff;
      transition: 0.2s ease-in;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); }
  .table-tabs .active {
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); }
  .table-tabs-small button {
    width: 152px; }
  .table-tabs .status {
    position: absolute;
    right: 2px; }

.fond-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  background-color: red;
  width: 500px;
  height: 500px;
  display: none; }

.show {
  display: block; }

.tabs-three-buttons {
  justify-content: space-between; }

.table {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
  .table tr {
    border-bottom: 1px solid #D2D6DA; }
    .table tr:hover {
      background-color: #F8F9FA; }
    .table tr:last-child {
      border-bottom: none !important; }
    .table tr td {
      font-size: 15px;
      padding-left: 20px;
      color: #3A416F; }
      .table tr td:first-child {
        width: 6%;
        font-weight: 600; }
      .table tr td:nth-child(2) {
        width: 25%;
        font-weight: 600; }
      .table tr td:nth-child(3) {
        width: 25%; }
      .table tr td:nth-child(4) {
        width: 15%; }
      .table tr td:nth-child(5) {
        width: 19%; }
      .table tr td:last-child {
        width: 20%; }
  .table .row-not-finished-document {
    background-color: #f3901a; }
    .table .row-not-finished-document td {
      color: white !important; }
    .table .row-not-finished-document:hover {
      background-color: rgba(255, 225, 187, 0.9) !important; }
  .table .green-row {
    background-color: #A3DBA3 !important; }
    .table .green-row td {
      color: #fff !important; }
    .table .green-row .delete-icon g, .table .green-row .delete-icon rect {
      fill: #fff !important; }
    .table .green-row:hover {
      background-color: rgba(163, 219, 163, 0.9) !important; }
  .table .red-row {
    background-color: #FD5C70 !important; }
    .table .red-row td {
      color: #fff !important; }
    .table .red-row .delete-icon g, .table .red-row .delete-icon rect {
      fill: #fff !important; }
    .table .red-row:hover {
      background-color: rgba(253, 92, 112, 0.9) !important; }
  .table .orange-row {
    background-color: #FFE1BB !important; }
    .table .orange-row:hover {
      background-color: rgba(255, 225, 187, 0.9) !important; }
  .table thead th {
    border: none;
    padding-left: 20px; }
  .table thead .action-td {
    height: 43.5px; }

.table tbody tr.children {
  background-color: #E6EDF5; }

.action-td {
  display: flex;
  justify-content: flex-end;
  width: 100% !important;
  max-width: 100% !important;
  border-top: none; }
  .action-td button {
    background-color: transparent;
    border: none; }
    .action-td button svg {
      max-width: 17px; }
  .action-td .expand-dropdown {
    position: relative; }
    .action-td .expand-dropdown::after {
      content: ' ';
      border-bottom: 2px solid #818FA5;
      border-right: 2px solid #818FA5;
      transform: rotate(45deg);
      display: block;
      width: 8px;
      height: 8px; }

.sort-enabled {
  font-size: 12px;
  font-weight: 600;
  color: #818FA5;
  text-transform: uppercase; }

.news-counter {
  float: right;
  font-size: 14px;
  color: #3A416F;
  margin-top: 20px; }
  .news-counter .colored-green {
    color: #49B848; }
  .news-counter .colored-blue {
    color: #138DB9; }

.pagination {
  margin-top: 10px; }
  .pagination .page-item {
    width: 40px;
    height: 40px;
    background-color: #fff;
    margin-left: 5px;
    border-radius: 6px !important; }
    .pagination .page-item:first-child {
      position: relative;
      margin-left: 0; }
      .pagination .page-item:first-child::before {
        content: '';
        position: absolute;
        top: 40%;
        left: 48%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        background-color: transparent;
        border-top: 2px solid #3A416F;
        border-right: 2px solid #3A416F;
        transform: rotate(-136deg); }
    .pagination .page-item:last-child {
      position: relative; }
      .pagination .page-item:last-child::before {
        content: '';
        position: absolute;
        top: 43%;
        left: 40%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        background-color: transparent;
        border-top: 2px solid #3A416F;
        border-right: 2px solid #3A416F;
        transform: rotate(42deg); }
    .pagination .page-item .page-link {
      display: block;
      line-height: 40px;
      padding: 0;
      text-align: center;
      border: none;
      font-size: 13px;
      color: #3A416F;
      border-radius: 6px; }
      .pagination .page-item .page-link:focus {
        box-shadow: none !important;
        outline: none !important; }
  .pagination .page-item-next {
    width: 40px;
    height: 40px;
    background-color: #fff;
    margin-left: 5px;
    border-radius: 6px !important;
    cursor: pointer;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none; }
    .pagination .page-item-next:first-child {
      position: relative;
      margin-left: 0;
      width: 40px;
      height: 40px; }
      .pagination .page-item-next:first-child::before {
        content: '';
        position: absolute;
        top: 40%;
        left: 48%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        background-color: transparent;
        border-top: 2px solid #3A416F;
        border-right: 2px solid #3A416F;
        transform: rotate(-136deg); }
    .pagination .page-item-next:last-child {
      position: relative;
      width: 40px;
      height: 40px; }
      .pagination .page-item-next:last-child::before {
        content: '';
        position: absolute;
        top: 43%;
        left: 40%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        background-color: transparent;
        border-top: 2px solid #3A416F;
        border-right: 2px solid #3A416F;
        transform: rotate(42deg); }
    .pagination .page-item-next .page-link {
      line-height: 40px;
      width: 40px;
      height: 40px;
      padding: 0;
      text-align: center;
      border: none;
      font-size: 22px;
      color: #3A416F;
      font-weight: 500;
      border-radius: 6px; }
      .pagination .page-item-next .page-link:focus {
        box-shadow: none !important;
        outline: none !important; }
  .pagination .active .page-link {
    background-color: #138DB9 !important;
    color: #fff !important; }

.table-filter {
  display: flex;
  justify-content: flex-end;
  z-index: 9; }
  @media only screen and (max-width: 767px) {
    .table-filter {
      flex-direction: column;
      justify-content: unset; } }
  .table-filter > label {
    display: flex;
    align-items: center;
    padding-right: 50px;
    white-space: nowrap;
    color: #818FA5;
    font-size: 13px; }
    .table-filter > label select {
      height: 42px;
      margin-right: 15px;
      font-size: 13px; }
  .table-filter .filter-results .form-group {
    margin-bottom: 0; }
    .table-filter .filter-results .form-group label input {
      width: 215px;
      height: 42px;
      font-size: 13px; }
    .table-filter .filter-results .form-group input {
      width: 215px;
      height: 42px;
      font-size: 13px; }

.sort-up-icon {
  position: relative;
  top: 1px;
  left: 2px;
  width: 8.5px; }
  .sort-up-icon:hover {
    cursor: pointer; }

.sort-down-icon {
  position: relative;
  top: -1px;
  width: 8.5px; }
  .sort-down-icon:hover {
    cursor: pointer; }

.list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 15px 10px 15px;
  background-color: #fff;
  border-radius: 6px 6px 0 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
  .list-header > div {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .list-header label {
    font-size: 21px;
    font-weight: 600;
    color: #3A416F;
    margin-bottom: 5px;
    padding-left: 15px; }
  .list-header .filter-results .form-group {
    margin-bottom: 0; }
    .list-header .filter-results .form-group label {
      margin-bottom: 0; }
      .list-header .filter-results .form-group label input {
        width: 215px;
        height: 42px;
        font-size: 13px; }

.table-modifed table {
  position: relative;
  top: -5px; }
  .table-modifed table td:nth-child(2) {
    width: 75%; }

.table-modifed .news-counter {
  margin-top: 20px; }

.ml-15 {
  margin-left: 15px; }

.mr-15 {
  margin-right: 15px; }

.modal-with-list-builder .table {
  box-shadow: none; }

.modal-with-list-builder .news-counter {
  display: none; }

.modal-with-list-builder .filter-results {
  display: none; }

.modal-with-list-builder .pagination {
  margin-left: 30px; }
  .modal-with-list-builder .pagination .page-item {
    background-color: #EBECF0; }
    .modal-with-list-builder .pagination .page-item .page-link {
      background-color: #EBECF0; }

.almbih-custom-form .add-form-container .custom-header {
  text-align: left; }

.almbih-custom-form .add-form-container .toogle-switch {
  position: relative;
  top: 0;
  margin-bottom: 20px; }
  .almbih-custom-form .add-form-container .toogle-switch > div {
    margin-left: 10px; }
  .almbih-custom-form .add-form-container .toogle-switch::before {
    content: 'Ima dozvolu';
    font-size: 14px;
    color: #3A3F56; }

.almbih-custom-form .add-form-container .form-group {
  text-align: left; }

.two-form-block {
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
  padding: 30px;
  border-radius: 6px;
  margin-left: 0;
  margin-right: 0; }
  .two-form-block .list-builder-left {
    padding-left: 0; }
    .two-form-block .list-builder-left h6 {
      font-size: 22px;
      font-weight: 700;
      color: #3A416F;
      margin-bottom: 20px; }
  .two-form-block .list-builder-right h6 {
    font-size: 22px;
    font-weight: 700;
    color: #3A416F;
    margin-bottom: 20px; }
  .two-form-block .table-container {
    padding: 0; }
    .two-form-block .table-container .table {
      box-shadow: none; }
      .two-form-block .table-container .table thead tr th {
        display: inline-block; }
        .two-form-block .table-container .table thead tr th:nth-child(3) {
          width: 24%; }
        .two-form-block .table-container .table thead tr th .sort {
          display: none; }

.one-form-block {
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
  padding: 30px;
  border-radius: 6px;
  margin-left: 0;
  margin-right: 0; }
  .one-form-block h6 {
    font-size: 22px;
    font-weight: 700;
    color: #3A416F;
    margin-bottom: 20px; }
  .one-form-block .table-container {
    padding: 0; }
    .one-form-block .table-container .table {
      box-shadow: none; }
      .one-form-block .table-container .table thead tr th {
        display: inline-block; }
        .one-form-block .table-container .table thead tr th:nth-child(2) {
          width: 28%; }
        .one-form-block .table-container .table thead tr th .sort {
          display: none; }

.next-button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 20px; }

.two-buttons {
  display: flex; }
  .two-buttons button {
    width: auto;
    padding: 0 35px; }
    .two-buttons button:first-child::before {
      left: 10% !important; }
    .two-buttons button:last-child {
      margin-left: 15px; }
      .two-buttons button:last-child::before {
        left: 8%; }

.collection-order .two-buttons button::before {
  left: 17% !important; }

.approval-document {
  width: 100%;
  margin: auto; }
  .approval-document .approval-document-header {
    width: 60%;
    margin: auto;
    text-align: center; }
    .approval-document .approval-document-header svg {
      margin: auto; }
    .approval-document .approval-document-header h6 {
      text-align: left;
      margin-top: 15px; }
  .approval-document .approval-document-body {
    padding-top: 20px; }

.line-container {
  display: flex;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px; }
  .line-container .progress-line {
    position: relative;
    margin: 0 auto;
    border-radius: 10px;
    width: 90%;
    background: grey;
    height: 2px;
    z-index: -1;
    display: flex;
    justify-content: space-between; }
    .line-container .progress-line .progress {
      position: absolute;
      width: 75%;
      height: 100%;
      background: #138DB9;
      transition: width 1s ease-in-out; }
  .line-container .status {
    position: relative;
    top: -7.5px; }
    .line-container .status .dot {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: white;
      border: 2px solid #818FA5;
      cursor: pointer; }
      .line-container .status .dot.current {
        background: white;
        border: 2px solid #818FA5; }
      .line-container .status .dot.completed {
        position: relative;
        top: 1.5px;
        background: #138DB9;
        border: 2px solid #138DB9; }

/*========== FOOTER ==========*/
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px; }
  @media only screen and (max-width: 767px) {
    .footer {
      flex-direction: column; } }
  .footer-left {
    display: flex; }
    .footer-left button {
      display: flex;
      align-items: center;
      padding: 0 15px;
      height: 50px;
      outline: none;
      border: none;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
      @media (min-width: 1200px) and (max-width: 1399px) {
        .footer-left button {
          padding: 0 10px; } }
      .footer-left button:nth-child(2) {
        margin: 0 20px; }
      .footer-left button .box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 6px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
        .footer-left button .box svg {
          max-width: 20px; }
      .footer-left button .box-red {
        background-color: #FD5C70; }
      .footer-left button .box-blue {
        background-color: #fff; }
      .footer-left button p {
        margin-bottom: 0;
        padding-left: 10px;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        color: #3A416F; }
        @media (min-width: 1200px) and (max-width: 1399px) {
          .footer-left button p {
            font-size: 11px; } }
    .footer-left .blue-button {
      display: flex;
      align-items: center;
      padding: 0 15px;
      height: 50px;
      outline: none;
      border: none;
      background-color: #138DB9;
      border-radius: 6px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
      @media (min-width: 1200px) and (max-width: 1399px) {
        .footer-left .blue-button {
          padding: 0 10px; } }
      .footer-left .blue-button:nth-child(2) {
        margin: 0 20px; }
      .footer-left .blue-button .box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 6px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
      .footer-left .blue-button .box-red {
        background-color: #FD5C70; }
      .footer-left .blue-button .box-blue {
        background-color: #fff; }
      .footer-left .blue-button p {
        margin-bottom: 0;
        padding-left: 10px;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff; }
        @media (min-width: 1200px) and (max-width: 1399px) {
          .footer-left .blue-button p {
            font-size: 11px; } }
    .footer-left .dark-blue-button {
      display: flex;
      align-items: center;
      padding: 0 15px;
      height: 50px;
      outline: none;
      border: none;
      background-color: #3A416F;
      border-radius: 6px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
      @media (min-width: 1200px) and (max-width: 1399px) {
        .footer-left .dark-blue-button {
          padding: 0 10px; } }
      .footer-left .dark-blue-button:nth-child(2) {
        margin: 0 20px; }
      .footer-left .dark-blue-button .box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 6px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
      .footer-left .dark-blue-button .box-red {
        background-color: #FD5C70; }
      .footer-left .dark-blue-button .box-blue {
        background-color: #fff; }
      .footer-left .dark-blue-button p {
        margin-bottom: 0;
        padding-left: 10px;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff; }
        @media (min-width: 1200px) and (max-width: 1399px) {
          .footer-left .dark-blue-button p {
            font-size: 11px; } }
      .footer-left .dark-blue-button:hover {
        background-color: #3A416F;
        transition: 0.2s ease-in; }
        .footer-left .dark-blue-button:hover .box-red {
          background-color: #fff; }
        .footer-left .dark-blue-button:hover p {
          color: #fff; }
        .footer-left .dark-blue-button:hover svg path,
        .footer-left .dark-blue-button:hover svg line,
        .footer-left .dark-blue-button:hover svg circle {
          stroke: #3A3F56; }
    .footer-left .gray-button {
      display: flex;
      align-items: center;
      padding: 0 15px;
      height: 50px;
      outline: none;
      border: none;
      background-color: #818FA5;
      border-radius: 6px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
      @media (min-width: 1200px) and (max-width: 1399px) {
        .footer-left .gray-button {
          padding: 0 10px; } }
      .footer-left .gray-button:nth-child(2) {
        margin: 0 20px; }
      .footer-left .gray-button .box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 6px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
      .footer-left .gray-button .box-red {
        background-color: #FD5C70; }
      .footer-left .gray-button .box-blue {
        background-color: #fff; }
      .footer-left .gray-button p {
        margin-bottom: 0;
        padding-left: 10px;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff; }
        @media (min-width: 1200px) and (max-width: 1399px) {
          .footer-left .gray-button p {
            font-size: 11px; } }
      .footer-left .gray-button:hover {
        background-color: #3A416F;
        transition: 0.2s ease-in; }
        .footer-left .gray-button:hover .box-red {
          background-color: #fff; }
        .footer-left .gray-button:hover p {
          color: #fff; }
        .footer-left .gray-button:hover svg path,
        .footer-left .gray-button:hover svg line,
        .footer-left .gray-button:hover svg circle {
          stroke: #3A3F56; }
    .footer-left .modified-div-wrap {
      margin-left: 20px; }
      .footer-left .modified-div-wrap h6 {
        font-size: 14px;
        font-weight: 600;
        color: #818FA5;
        margin-bottom: 0;
        line-height: 16px;
        white-space: nowrap; }
      .footer-left .modified-div-wrap p {
        white-space: nowrap;
        font-size: 14px;
        font-weight: 400;
        color: #818FA5;
        margin-bottom: 0;
        line-height: 16px; }
    .footer-left .discount-wrap {
      display: flex;
      align-items: center; }
      .footer-left .discount-wrap div {
        padding: 0 20px; }
        .footer-left .discount-wrap div:first-child {
          border-right-color: #D2D6DA;
          border-right-width: 1px;
          border-right-style: solid; }
        .footer-left .discount-wrap div h6 {
          font-size: 13px;
          font-weight: 600;
          color: #818FA5;
          margin-bottom: 0;
          line-height: 20px; }
        .footer-left .discount-wrap div p {
          font-size: 19px;
          font-weight: 400;
          color: #3A416F;
          white-space: nowrap;
          margin-bottom: 0;
          line-height: 29px; }
  .footer-right {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .footer-right {
        flex-direction: column; } }
    .footer-right .discount-wrap {
      display: flex;
      align-items: center; }
      .footer-right .discount-wrap div {
        padding: 0 20px; }
        .footer-right .discount-wrap div:first-child {
          border-right-color: #D2D6DA;
          border-right-width: 1px;
          border-right-style: solid; }
        .footer-right .discount-wrap div h6 {
          font-size: 13px;
          font-weight: 600;
          color: #818FA5;
          margin-bottom: 0;
          line-height: 20px; }
        .footer-right .discount-wrap div p {
          font-size: 19px;
          font-weight: 400;
          color: #3A416F;
          white-space: nowrap;
          margin-bottom: 0;
          line-height: 29px; }
    .footer-right .info-transparent {
      display: flex; }
      .footer-right .info-transparent .col-info {
        padding: 0 40px; }
        @media (min-width: 1200px) and (max-width: 1399px) {
          .footer-right .info-transparent .col-info {
            padding: 0 10px; } }
        @media (min-width: 1400px) and (max-width: 1850px) {
          .footer-right .info-transparent .col-info {
            padding: 0 20px; } }
        @media only screen and (max-width: 767px) {
          .footer-right .info-transparent .col-info {
            padding: 0 5px; } }
        .footer-right .info-transparent .col-info:nth-child(2) {
          border-left: 1px solid #D2D6DA;
          border-right: 1px solid #D2D6DA; }
        .footer-right .info-transparent .col-info p {
          font-size: 13px;
          font-weight: 600;
          color: #818FA5;
          margin-bottom: 5px; }
        .footer-right .info-transparent .col-info span {
          font-size: 21px;
          color: #3A416F;
          text-transform: uppercase; }
          @media (min-width: 1200px) and (max-width: 1399px) {
            .footer-right .info-transparent .col-info span {
              font-size: 15px; } }
          @media (min-width: 1400px) and (max-width: 1850px) {
            .footer-right .info-transparent .col-info span {
              font-size: 17px; } }
          @media only screen and (max-width: 767px) {
            .footer-right .info-transparent .col-info span {
              font-size: 15px; } }
    .footer-right .info-colored {
      display: flex;
      background-color: #138DB9;
      border-radius: 6px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
      padding: 10px 0; }
      .footer-right .info-colored .col-info {
        padding: 0 40px; }
        @media (min-width: 1200px) and (max-width: 1399px) {
          .footer-right .info-colored .col-info {
            padding: 0 10px; } }
        @media (min-width: 1400px) and (max-width: 1850px) {
          .footer-right .info-colored .col-info {
            padding: 0 20px; } }
        @media only screen and (max-width: 767px) {
          .footer-right .info-colored .col-info {
            padding: 0 5px; } }
        .footer-right .info-colored .col-info:nth-child(2) {
          border-left: 1px solid #D2D6DA;
          border-right: 1px solid #D2D6DA; }
        .footer-right .info-colored .col-info p {
          font-size: 13px;
          font-weight: 600;
          color: #fff;
          margin-bottom: 5px; }
        .footer-right .info-colored .col-info span {
          font-size: 21px;
          font-weight: 700;
          color: #fff;
          text-transform: uppercase; }
          @media (min-width: 1200px) and (max-width: 1399px) {
            .footer-right .info-colored .col-info span {
              font-size: 15px; } }
          @media (min-width: 1400px) and (max-width: 1850px) {
            .footer-right .info-colored .col-info span {
              font-size: 17px; } }
          @media only screen and (max-width: 767px) {
            .footer-right .info-colored .col-info span {
              font-size: 15px; } }

/*========== FORM BUILDER ==========*/
.add-form-container {
  padding: 25px 15px 10px 15px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
  .add-form-container .toogle-switch {
    position: relative;
    top: 27px; }
  .add-form-container > .custom-header {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    .add-form-container > .custom-header h6 {
      font-size: 21px;
      font-weight: 600;
      color: #3A416F;
      margin-bottom: 20px;
      margin-left: 15px; }
    .add-form-container > .custom-header span {
      position: relative;
      font-size: 15px;
      padding-right: 30px;
      color: #3A416F;
      white-space: nowrap; }
      .add-form-container > .custom-header span:hover {
        cursor: pointer; }
      .add-form-container > .custom-header span::after {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        right: 15px;
        width: 8px;
        height: 8px;
        background-color: transparent;
        border-top: 1px solid #3A416F;
        border-right: 1px solid #3A416F;
        transform: rotate(133deg); }
  .add-form-container > h6 {
    font-size: 21px;
    font-weight: 600;
    color: #3A416F;
    margin-bottom: 20px;
    margin-left: 15px; }
  .add-form-container .form-group label {
    font-size: 13px;
    font-weight: 600;
    color: #3A416F; }
  .add-form-container .form-group input,
  .add-form-container .form-group select {
    height: 42px;
    border: 1px solid #D2D6DA;
    border-radius: 6px;
    font-size: 13px;
    color: #818FA5;
    padding-left: 15px;
    margin-bottom: 22px; }

.add-form-container-transparent {
  background-color: transparent;
  box-shadow: none;
  padding-top: 0; }

.submit-button {
  width: 206px;
  height: 50px;
  border-radius: 6px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  background-color: #49B848;
  border: none;
  outline: none;
  margin-top: 40px;
  position: relative;
  left: -15px; }
  .submit-button:hover {
    opacity: 0.94;
    transition: 0.2s ease-in; }

.fond-button {
  display: block;
  width: 100%;
  height: 52px;
  line-height: 35px;
  background-color: #138DB9;
  border-radius: 6px;
  font-size: 13px;
  color: #fff;
  text-transform: uppercase;
  outline: none;
  border: none; }
  .fond-button:hover {
    opacity: 0.9;
    transition: 0.2s ease-in; }

.posting-button {
  position: relative;
  top: 1.5px;
  left: 4px;
  display: block;
  width: 110px;
  height: 50px;
  line-height: 35px;
  background-color: #3A416F;
  border-radius: 6px;
  font-size: 13px;
  color: #fff;
  text-transform: unset;
  outline: none;
  border: none; }
  .posting-button:hover {
    opacity: 0.9;
    background-color: #3A416F;
    transition: 0.2s ease-in; }

.show-article-button {
  position: relative;
  top: 28px;
  display: block;
  width: 125px;
  height: 42px;
  line-height: 32px;
  background-color: #138DB9;
  border-radius: 6px;
  font-size: 13px;
  color: #fff;
  outline: none;
  border: none; }

.option-header {
  justify-content: center;
  padding: 5px 0;
  height: 50px;
  background-color: #fff;
  border-radius: 6px;
  margin-left: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
  .option-header > .add-form-container {
    background-color: unset;
    padding: unset;
    box-shadow: none;
    z-index: 9; }
    .option-header > .add-form-container > .form-group {
      margin-bottom: 0; }

.next-button {
  position: relative;
  right: -15px;
  display: block !important;
  width: 140px;
  height: 46px !important;
  line-height: 47px;
  border-radius: 6px;
  background-color: #138DB9;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  outline: none;
  border: none; }
  .next-button::before {
    content: '';
    position: absolute;
    top: 44%;
    left: 25%;
    transform: translate(0, -50%);
    width: 7px;
    height: 7px;
    background-color: transparent;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(46deg); }

.next-button-right {
  float: right;
  margin-top: 15px;
  position: relative;
  right: 0px; }

.options-button {
  background-color: transparent;
  border: none; }
  .options-button:hover {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important; }
  .options-button:focus {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important; }
  .options-button .option-buttons-container {
    display: flex;
    align-items: center; }
    .options-button .option-buttons-container .option {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      background-color: #49B848;
      border-radius: 6px; }
    .options-button .option-buttons-container .green {
      background-color: #49B848; }
    .options-button .option-buttons-container .blue {
      background-color: #138DB9; }
    .options-button .option-buttons-container .red {
      background-color: #FD5C70; }
    .options-button .option-buttons-container span {
      display: inline-block;
      font-size: 13px;
      color: #3A416F;
      padding-left: 10px; }

.input-transparent-container input {
  background-color: #F8F9FA; }
  .input-transparent-container input:focus {
    background-color: #F8F9FA; }

.square-info-container {
  display: inline-flex; }
  .square-info-container .square-info {
    display: inline-block;
    position: relative;
    padding: 30px 20px 20px 20px;
    border: 1px solid #D2D6DA;
    border-radius: 10px;
    margin-left: 35px; }
    .square-info-container .square-info:first-child {
      margin-left: 15px; }
    .square-info-container .square-info-title {
      position: absolute;
      top: -17px;
      left: 16px;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      color: #818FA5;
      background-color: #F8F9FA;
      padding: 6px 8px; }
    .square-info-container .square-info-content h4 {
      font-size: 19px;
      font-weight: 700;
      color: #3A416F;
      text-transform: uppercase; }
    .square-info-container .square-info-content h6 {
      font-size: 15px;
      font-weight: 600;
      color: #3A416F; }
      .square-info-container .square-info-content h6 span {
        font-weight: 400;
        text-transform: uppercase; }
    .square-info-container .square-info-content-vertical .vertical-row {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px; }
      .square-info-container .square-info-content-vertical .vertical-row:first-child::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 100%;
        height: 1px;
        background-color: #D2D6DA; }
      .square-info-container .square-info-content-vertical .vertical-row:last-child {
        padding-top: 20px; }
      .square-info-container .square-info-content-vertical .vertical-row-box {
        padding-left: 25px; }
        .square-info-container .square-info-content-vertical .vertical-row-box:first-child {
          padding-left: 0; }
        .square-info-container .square-info-content-vertical .vertical-row-box h6 {
          font-size: 13px;
          font-weight: 600;
          color: #818FA5; }
        .square-info-container .square-info-content-vertical .vertical-row-box span {
          font-size: 15px;
          text-transform: uppercase;
          color: #3A416F; }
  .square-info-container .square-info-form-builder {
    padding: 22px 20px 10px 20px;
    width: 100% !important; }
    .square-info-container .square-info-form-builder .square-info-content {
      word-break: break-all; }

.square-info-container-form-builder {
  width: 100% !important;
  height: 100% !important; }

.form-control:disabled,
.disabled,
.form-control[readonly] {
  background-color: #F8F9FA; }

.input-group-custom .col-lg-1:first-child,
.input-group-custom .col-lg-2:first-child,
.input-group-custom .col-lg-3:first-child,
.input-group-custom .col-lg-4:first-child,
.input-group-custom .col-lg-5:first-child,
.input-group-custom .col-lg-6:first-child,
.input-group-custom .col-lg-7:first-child,
.input-group-custom .col-lg-8:first-child,
.input-group-custom .col-lg-9:first-child,
.input-group-custom .col-lg-10:first-child {
  padding-right: 0; }
  .input-group-custom .col-lg-1:first-child input,
  .input-group-custom .col-lg-2:first-child input,
  .input-group-custom .col-lg-3:first-child input,
  .input-group-custom .col-lg-4:first-child input,
  .input-group-custom .col-lg-5:first-child input,
  .input-group-custom .col-lg-6:first-child input,
  .input-group-custom .col-lg-7:first-child input,
  .input-group-custom .col-lg-8:first-child input,
  .input-group-custom .col-lg-9:first-child input,
  .input-group-custom .col-lg-10:first-child input {
    border-radius: 10px 0 0 10px; }

.input-group-custom .col-lg-1:last-child,
.input-group-custom .col-lg-2:last-child,
.input-group-custom .col-lg-3:last-child,
.input-group-custom .col-lg-4:last-child,
.input-group-custom .col-lg-5:last-child,
.input-group-custom .col-lg-6:last-child,
.input-group-custom .col-lg-7:last-child,
.input-group-custom .col-lg-8:last-child,
.input-group-custom .col-lg-9:last-child,
.input-group-custom .col-lg-10:last-child {
  padding-left: 0; }
  .input-group-custom .col-lg-1:last-child input,
  .input-group-custom .col-lg-2:last-child input,
  .input-group-custom .col-lg-3:last-child input,
  .input-group-custom .col-lg-4:last-child input,
  .input-group-custom .col-lg-5:last-child input,
  .input-group-custom .col-lg-6:last-child input,
  .input-group-custom .col-lg-7:last-child input,
  .input-group-custom .col-lg-8:last-child input,
  .input-group-custom .col-lg-9:last-child input,
  .input-group-custom .col-lg-10:last-child input {
    border-radius: 0 10px 10px 0;
    border-left: none; }

.entrance-custom-col .col-lg-1,
.entrance-custom-col .col-lg-2 {
  max-width: 186px;
  width: 100%; }

.entrance-custom-col .col-lg-3 {
  max-width: 253px;
  width: 100%; }

.custom-input-width {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; }
  .custom-input-width .form-group {
    margin: 0 10px; }
    .custom-input-width .form-group label {
      white-space: nowrap; }
    .custom-input-width .form-group:first-child {
      margin-left: 0;
      width: 6%; }
    .custom-input-width .form-group:nth-child(2) {
      width: 10%; }
    .custom-input-width .form-group:nth-child(3) {
      width: 10%; }
    .custom-input-width .form-group:nth-child(4) {
      width: 10%; }
    .custom-input-width .form-group:nth-child(7) {
      width: 6%; }
    .custom-input-width .form-group:nth-child(8) {
      width: 6%;
      margin-right: 0; }
  .custom-input-width .row {
    margin: 0; }
  .custom-input-width .input-group-custom {
    display: flex;
    align-items: center;
    width: 15%;
    padding: 0 10px;
    margin: 0; }
    .custom-input-width .input-group-custom .form-group {
      margin: 0; }
      .custom-input-width .input-group-custom .form-group:first-child {
        width: 38%;
        padding-right: 0; }
        .custom-input-width .input-group-custom .form-group:first-child .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .custom-input-width .input-group-custom .form-group:nth-child(2) {
        width: 62%;
        margin-left: -1px;
        padding-left: 0; }
        .custom-input-width .input-group-custom .form-group:nth-child(2) .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
  .custom-input-width .input-group-custom-2 {
    display: flex;
    align-items: center;
    width: 23%;
    padding: 0 10px;
    margin: 0; }
    .custom-input-width .input-group-custom-2 .form-group {
      margin: 0; }
      .custom-input-width .input-group-custom-2 .form-group:first-child {
        width: 23%;
        margin-right: 0;
        padding-right: 0; }
        .custom-input-width .input-group-custom-2 .form-group:first-child .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .custom-input-width .input-group-custom-2 .form-group:nth-child(2) {
        width: 77%;
        margin-left: -1px;
        padding-left: 0; }
        .custom-input-width .input-group-custom-2 .form-group:nth-child(2) .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }

.custom-input-width-5 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; }
  .custom-input-width-5 .form-group {
    margin: 0 10px; }
    .custom-input-width-5 .form-group label {
      white-space: nowrap; }
    .custom-input-width-5 .form-group:first-child {
      margin-left: 0;
      width: 6%; }
    .custom-input-width-5 .form-group:nth-child(2) {
      width: 10%; }
    .custom-input-width-5 .form-group:nth-child(3) {
      width: 10%; }
    .custom-input-width-5 .form-group:nth-child(4) {
      width: 10%; }
    .custom-input-width-5 .form-group:nth-child(7) {
      width: 6%; }
    .custom-input-width-5 .form-group:nth-child(8) {
      width: 6%;
      margin-right: 0; }
  .custom-input-width-5 .row {
    margin: 0; }
  .custom-input-width-5 .input-group-custom {
    display: flex;
    align-items: flex-end;
    width: 15%;
    padding: 0;
    margin: 0; }
    .custom-input-width-5 .input-group-custom .form-group {
      margin: 0; }
      .custom-input-width-5 .input-group-custom .form-group:first-child {
        width: 38%;
        padding-right: 0; }
        .custom-input-width-5 .input-group-custom .form-group:first-child .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .custom-input-width-5 .input-group-custom .form-group:nth-child(2) {
        width: 62%;
        margin-left: -1px;
        padding-left: 0; }
        .custom-input-width-5 .input-group-custom .form-group:nth-child(2) .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }

.custom-input-width-6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; }
  .custom-input-width-6 .form-group {
    margin: 0 10px; }
    .custom-input-width-6 .form-group label {
      white-space: nowrap; }
    .custom-input-width-6 .form-group:first-child {
      margin-left: 0;
      width: 13.5%; }
    .custom-input-width-6 .form-group:nth-child(2) {
      width: 27%; }
    .custom-input-width-6 .form-group:nth-child(3) {
      width: 9.2%; }
    .custom-input-width-6 .form-group:nth-child(4) {
      width: 9.2%; }
    .custom-input-width-6 .form-group:nth-child(5) {
      width: 9.9%; }
    .custom-input-width-6 .form-group:nth-child(6) {
      width: 10.5%; }
    .custom-input-width-6 .form-group:nth-child(7) {
      width: 12.6%;
      margin-right: 0; }
  .custom-input-width-6 .row {
    margin: 0; }
  .custom-input-width-6 .input-group-custom {
    display: flex;
    align-items: flex-end;
    width: 15%;
    padding: 0;
    margin: 0; }
    .custom-input-width-6 .input-group-custom .form-group {
      margin: 0; }
      .custom-input-width-6 .input-group-custom .form-group:first-child {
        width: 38%;
        padding-right: 0; }
        .custom-input-width-6 .input-group-custom .form-group:first-child .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .custom-input-width-6 .input-group-custom .form-group:nth-child(2) {
        width: 62%;
        margin-left: -1px;
        padding-left: 0; }
        .custom-input-width-6 .input-group-custom .form-group:nth-child(2) .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }

.custom-input-width-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; }
  .custom-input-width-2 .form-group {
    margin: 0 10px; }
    .custom-input-width-2 .form-group label {
      white-space: nowrap; }
    .custom-input-width-2 .form-group:first-child {
      margin-left: 0;
      width: 5%; }
    .custom-input-width-2 .form-group:nth-child(2) {
      width: 20.5%; }
    .custom-input-width-2 .form-group:nth-child(3) {
      width: 9.7%; }
    .custom-input-width-2 .form-group:nth-child(4) {
      width: 8.6%; }
    .custom-input-width-2 .form-group:nth-child(5) {
      width: 6.8%; }
    .custom-input-width-2 .form-group:nth-child(6) {
      width: 4.6%; }
    .custom-input-width-2 .form-group:last-child {
      width: 4.8%;
      margin-right: 0; }
  .custom-input-width-2 .row {
    margin: 0; }
  .custom-input-width-2 .input-group-custom {
    display: flex;
    align-items: center;
    width: 11%;
    padding: 0 10px;
    margin: 0; }
    .custom-input-width-2 .input-group-custom .form-group {
      margin: 0; }
      .custom-input-width-2 .input-group-custom .form-group:first-child {
        width: 52%;
        padding-right: 0; }
        .custom-input-width-2 .input-group-custom .form-group:first-child .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .custom-input-width-2 .input-group-custom .form-group:nth-child(2) {
        width: 48%;
        margin-left: -1px;
        padding-left: 0; }
        .custom-input-width-2 .input-group-custom .form-group:nth-child(2) .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
  .custom-input-width-2 .input-group-custom-2 {
    display: flex;
    align-items: center;
    width: 9.3%;
    padding: 0 10px;
    margin: 0; }
    .custom-input-width-2 .input-group-custom-2 .form-group {
      margin: 0; }
      .custom-input-width-2 .input-group-custom-2 .form-group:first-child {
        width: 44%;
        margin-right: 0;
        padding-right: 0; }
        .custom-input-width-2 .input-group-custom-2 .form-group:first-child .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .custom-input-width-2 .input-group-custom-2 .form-group:nth-child(2) {
        width: 56%;
        margin-left: -1px;
        padding-left: 0; }
        .custom-input-width-2 .input-group-custom-2 .form-group:nth-child(2) .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }

.custom-input-width-4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding-right: 18%; }
  .custom-input-width-4 .form-group {
    margin: 0 10px; }
    .custom-input-width-4 .form-group label {
      white-space: nowrap; }
    .custom-input-width-4 .form-group:first-child {
      margin-left: 0;
      width: 7.8%; }
    .custom-input-width-4 .form-group:nth-child(2) {
      width: 12.3%; }
    .custom-input-width-4 .form-group:nth-child(3) {
      width: 12.3%; }
  .custom-input-width-4 .row {
    margin: 0; }
  .custom-input-width-4 .input-group-custom {
    display: flex;
    align-items: center;
    width: 31%;
    padding: 0 10px;
    margin: 0; }
    .custom-input-width-4 .input-group-custom .form-group {
      margin: 0; }
      .custom-input-width-4 .input-group-custom .form-group:first-child {
        width: 30%;
        padding-right: 0; }
        .custom-input-width-4 .input-group-custom .form-group:first-child .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .custom-input-width-4 .input-group-custom .form-group:nth-child(2) {
        width: 70%;
        margin-left: -1px;
        padding-left: 0; }
        .custom-input-width-4 .input-group-custom .form-group:nth-child(2) .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
  .custom-input-width-4 .input-group-custom-2 {
    display: flex;
    align-items: center;
    width: 20%;
    padding: 0 10px;
    margin: 0; }
    .custom-input-width-4 .input-group-custom-2 .form-group {
      margin: 0; }
      .custom-input-width-4 .input-group-custom-2 .form-group:first-child {
        width: 30%;
        margin-right: 0;
        padding-right: 0; }
        .custom-input-width-4 .input-group-custom-2 .form-group:first-child .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .custom-input-width-4 .input-group-custom-2 .form-group:nth-child(2) {
        width: 70%;
        margin-left: -1px;
        padding-left: 0; }
        .custom-input-width-4 .input-group-custom-2 .form-group:nth-child(2) .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
  .custom-input-width-4 .limits-info-div {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    top: -13px; }
    .custom-input-width-4 .limits-info-div .limit-info-item {
      display: flex;
      flex-direction: column;
      width: 50%;
      margin-bottom: 12px; }
      .custom-input-width-4 .limits-info-div .limit-info-item h6 {
        font-size: 11px;
        font-weight: 600;
        color: #818FA5;
        margin-bottom: 1px; }
      .custom-input-width-4 .limits-info-div .limit-info-item p {
        font-size: 15px;
        font-weight: 400;
        color: #3A416F;
        margin-bottom: 0; }
  .custom-input-width-4 .limit-info-note span {
    font-size: 11px;
    text-transform: uppercase;
    color: #818FA5; }
  .custom-input-width-4 .limit-info-note h6 {
    font-size: 15px;
    color: #3A416F;
    padding-right: 35px; }

.custom-input-width-3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; }
  .custom-input-width-3 .form-group {
    margin: 0 10px; }
    .custom-input-width-3 .form-group label {
      white-space: nowrap; }
    .custom-input-width-3 .form-group:first-child {
      margin-left: 0;
      width: 5%; }
    .custom-input-width-3 .form-group:nth-child(2) {
      width: 24%; }
    .custom-input-width-3 .form-group:nth-child(3) {
      width: 10%; }
    .custom-input-width-3 .form-group:nth-child(4) {
      width: 8.8%; }
    .custom-input-width-3 .form-group:nth-child(5) {
      width: 5%; }
    .custom-input-width-3 .form-group:nth-child(6) {
      width: 7%;
      margin-right: 0; }
  .custom-input-width-3 .row {
    margin: 0; }
  .custom-input-width-3 .input-group-custom {
    display: flex;
    align-items: center;
    width: 11%;
    padding: 0 10px;
    margin: 0; }
    .custom-input-width-3 .input-group-custom .form-group {
      margin: 0; }
      .custom-input-width-3 .input-group-custom .form-group:first-child {
        width: 50%;
        padding-right: 0; }
        .custom-input-width-3 .input-group-custom .form-group:first-child .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .custom-input-width-3 .input-group-custom .form-group:nth-child(2) {
        width: 50%;
        margin-left: -1px;
        padding-left: 0; }
        .custom-input-width-3 .input-group-custom .form-group:nth-child(2) .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
  .custom-input-width-3 .input-group-custom-2 {
    display: flex;
    align-items: center;
    width: 15%;
    padding: 0 10px;
    margin: 0; }
    .custom-input-width-3 .input-group-custom-2 .form-group {
      margin: 0; }
      .custom-input-width-3 .input-group-custom-2 .form-group:first-child {
        width: 33%;
        margin-right: 0;
        padding-right: 0; }
        .custom-input-width-3 .input-group-custom-2 .form-group:first-child .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .custom-input-width-3 .input-group-custom-2 .form-group:nth-child(2) {
        width: 33%;
        margin-left: -1px;
        padding-left: 0; }
        .custom-input-width-3 .input-group-custom-2 .form-group:nth-child(2) .form-control {
          border-radius: 0; }
      .custom-input-width-3 .input-group-custom-2 .form-group:nth-child(3) {
        width: 34%;
        margin-left: -1px;
        padding-left: 0; }
        .custom-input-width-3 .input-group-custom-2 .form-group:nth-child(3) .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }

.natural-discount-custom-col .custom-input-width-4 {
  padding-right: 0; }
  .natural-discount-custom-col .custom-input-width-4 .form-group:first-child {
    width: 8.3%; }
  .natural-discount-custom-col .custom-input-width-4 .form-group:nth-child(2) {
    width: 13.7%; }
  .natural-discount-custom-col .custom-input-width-4 .form-group:nth-child(3) {
    width: 13.7%; }
  .natural-discount-custom-col .custom-input-width-4 .input-group-custom {
    display: flex;
    align-items: center;
    width: 35%;
    padding: 0 10px;
    margin: 0; }
    .natural-discount-custom-col .custom-input-width-4 .input-group-custom .form-group {
      margin: 0; }
      .natural-discount-custom-col .custom-input-width-4 .input-group-custom .form-group:first-child {
        width: 30%;
        padding-right: 0; }
        .natural-discount-custom-col .custom-input-width-4 .input-group-custom .form-group:first-child .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .natural-discount-custom-col .custom-input-width-4 .input-group-custom .form-group:nth-child(2) {
        width: 70%;
        margin-left: -1px;
        padding-left: 0; }
        .natural-discount-custom-col .custom-input-width-4 .input-group-custom .form-group:nth-child(2) .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
  .natural-discount-custom-col .custom-input-width-4 .input-group-custom-2 {
    display: flex;
    align-items: center;
    width: 22%;
    padding: 0 10px;
    margin: 0; }
    .natural-discount-custom-col .custom-input-width-4 .input-group-custom-2 .form-group {
      margin: 0; }
      .natural-discount-custom-col .custom-input-width-4 .input-group-custom-2 .form-group:first-child {
        width: 33%;
        margin-right: 0;
        padding-right: 0; }
        .natural-discount-custom-col .custom-input-width-4 .input-group-custom-2 .form-group:first-child .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .natural-discount-custom-col .custom-input-width-4 .input-group-custom-2 .form-group:nth-child(2) {
        width: 63%;
        margin-left: -1px;
        padding-left: 0; }

.custom-input-row {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .custom-input-row .input-group-custom {
    display: flex; }

.custom-input-row-start {
  justify-content: flex-start; }
  .custom-input-row-start .form-group {
    margin-left: 20px !important; }
    .custom-input-row-start .form-group:first-child, .custom-input-row-start .form-group:last-child {
      margin-left: 0 !important; }
  .custom-input-row-start .row {
    margin-right: 20px !important;
    margin-left: 20px !important; }

.reservation-row-1 .form-group {
  margin: 0; }
  .reservation-row-1 .form-group label {
    white-space: nowrap; }
  .reservation-row-1 .form-group:first-child {
    margin-left: 0;
    width: 9.7%; }
  .reservation-row-1 .form-group:nth-child(2) {
    width: 12.2%; }
  .reservation-row-1 .form-group:nth-child(3) {
    width: 10.8%; }

.reservation-row-1 .input-group-custom-1-container {
  width: 36%;
  margin: 0; }
  .reservation-row-1 .input-group-custom-1-container .input-group-custom-1 {
    padding: 0;
    margin: 0; }
    .reservation-row-1 .input-group-custom-1-container .input-group-custom-1 .form-group {
      margin: 0; }
      .reservation-row-1 .input-group-custom-1-container .input-group-custom-1 .form-group label {
        white-space: nowrap; }
      .reservation-row-1 .input-group-custom-1-container .input-group-custom-1 .form-group:first-child {
        width: 27.4%;
        padding-right: 0; }
        .reservation-row-1 .input-group-custom-1-container .input-group-custom-1 .form-group:first-child .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .reservation-row-1 .input-group-custom-1-container .input-group-custom-1 .form-group:nth-child(2) {
        width: 79%;
        margin-left: -1px;
        padding-left: 0; }
        .reservation-row-1 .input-group-custom-1-container .input-group-custom-1 .form-group:nth-child(2) .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }

.reservation-row-1 .input-group-custom-2-container {
  display: flex;
  justify-content: flex-end;
  width: 28%; }
  .reservation-row-1 .input-group-custom-2-container .input-group-custom-2 {
    width: 100%;
    margin: 0; }
    .reservation-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group {
      margin: 0; }
      .reservation-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group label {
        white-space: nowrap; }
      .reservation-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group:first-child {
        width: 100%;
        max-width: 130px;
        padding-right: 0; }
        .reservation-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group:first-child .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .reservation-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group:nth-child(2) {
        width: 100%;
        max-width: 272px;
        margin-left: -1px;
        padding-left: 0; }
        .reservation-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group:nth-child(2) .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }

.kss-row-1 .form-group {
  margin: 0; }
  .kss-row-1 .form-group label {
    white-space: nowrap; }
  .kss-row-1 .form-group:first-child {
    margin-left: 0;
    width: 8%; }
  .kss-row-1 .form-group:nth-child(2) {
    width: 9%; }
  .kss-row-1 .form-group:nth-child(3) {
    width: 22%; }
  .kss-row-1 .form-group:nth-child(4) {
    width: 17.5%; }
  .kss-row-1 .form-group:nth-child(5) {
    width: 8.4%; }
  .kss-row-1 .form-group:nth-child(6) {
    width: 8.4%; }
  .kss-row-1 .form-group:last-child {
    width: 17.4%; }

.kss-row-1 .input-group-custom-1-container {
  width: 36%;
  margin: 0; }
  .kss-row-1 .input-group-custom-1-container .input-group-custom-1 {
    padding: 0;
    margin: 0; }
    .kss-row-1 .input-group-custom-1-container .input-group-custom-1 .form-group {
      margin: 0; }
      .kss-row-1 .input-group-custom-1-container .input-group-custom-1 .form-group label {
        white-space: nowrap; }
      .kss-row-1 .input-group-custom-1-container .input-group-custom-1 .form-group:first-child {
        width: 27.4%;
        padding-right: 0; }
        .kss-row-1 .input-group-custom-1-container .input-group-custom-1 .form-group:first-child .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .kss-row-1 .input-group-custom-1-container .input-group-custom-1 .form-group:nth-child(2) {
        width: 79%;
        margin-left: -1px;
        padding-left: 0; }
        .kss-row-1 .input-group-custom-1-container .input-group-custom-1 .form-group:nth-child(2) .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }

.kss-row-1 .input-group-custom-2-container {
  display: flex;
  justify-content: flex-end;
  width: 28%; }
  .kss-row-1 .input-group-custom-2-container .input-group-custom-2 {
    width: 100%;
    margin: 0; }
    .kss-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group {
      margin: 0; }
      .kss-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group label {
        white-space: nowrap; }
      .kss-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group:first-child {
        width: 100%;
        max-width: 130px;
        padding-right: 0; }
        .kss-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group:first-child .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .kss-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group:nth-child(2) {
        width: 100%;
        max-width: 272px;
        margin-left: -1px;
        padding-left: 0; }
        .kss-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group:nth-child(2) .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }

.reservation-row-2 .form-group {
  margin: 0 10px; }
  .reservation-row-2 .form-group label {
    white-space: nowrap; }
  .reservation-row-2 .form-group:first-child {
    margin-left: 0;
    width: 6%; }
  .reservation-row-2 .form-group:nth-child(2) {
    width: 48.5%; }
  .reservation-row-2 .form-group:nth-child(3) {
    width: 15%; }
  .reservation-row-2 .form-group:nth-child(4) {
    width: 15%; }
  .reservation-row-2 .form-group:nth-child(7) {
    width: 8%; }
  .reservation-row-2 .form-group:nth-child(8) {
    width: 12%;
    margin-right: 0; }

.reservation-row-2 .input-group-custom-1-container {
  margin: 0; }
  .reservation-row-2 .input-group-custom-1-container .input-group-custom-1 {
    display: flex;
    align-items: center;
    width: 11%;
    padding: 0 10px;
    margin: 0; }
    .reservation-row-2 .input-group-custom-1-container .input-group-custom-1 .form-group {
      margin: 0; }
      .reservation-row-2 .input-group-custom-1-container .input-group-custom-1 .form-group:first-child {
        width: 50%;
        padding-right: 0; }
        .reservation-row-2 .input-group-custom-1-container .input-group-custom-1 .form-group:first-child .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .reservation-row-2 .input-group-custom-1-container .input-group-custom-1 .form-group:nth-child(2) {
        width: 50%;
        margin-left: -1px;
        padding-left: 0; }
        .reservation-row-2 .input-group-custom-1-container .input-group-custom-1 .form-group:nth-child(2) .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }

.checkoutfond-row-1 .form-group {
  margin: 0;
  padding-left: 20px; }
  .checkoutfond-row-1 .form-group label {
    white-space: nowrap; }
  .checkoutfond-row-1 .form-group:first-child {
    margin-left: 0;
    padding-left: 15px; }
  .checkoutfond-row-1 .form-group:nth-child(2) {
    width: 68%; }
  .checkoutfond-row-1 .form-group:nth-child(3) {
    width: 15%; }

.checkoutfond-row-1 .input-group-custom-1-container {
  width: 36%;
  margin: 0; }
  .checkoutfond-row-1 .input-group-custom-1-container .input-group-custom-1 {
    padding: 0;
    margin: 0; }
    .checkoutfond-row-1 .input-group-custom-1-container .input-group-custom-1 .form-group {
      margin: 0; }
      .checkoutfond-row-1 .input-group-custom-1-container .input-group-custom-1 .form-group label {
        white-space: nowrap; }
      .checkoutfond-row-1 .input-group-custom-1-container .input-group-custom-1 .form-group:first-child {
        width: 27.4%;
        padding-right: 0; }
        .checkoutfond-row-1 .input-group-custom-1-container .input-group-custom-1 .form-group:first-child .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .checkoutfond-row-1 .input-group-custom-1-container .input-group-custom-1 .form-group:nth-child(2) {
        width: 79%;
        margin-left: -1px;
        padding-left: 0; }
        .checkoutfond-row-1 .input-group-custom-1-container .input-group-custom-1 .form-group:nth-child(2) .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }

.checkoutfond-row-1 .input-group-custom-2-container {
  display: flex;
  justify-content: flex-end;
  width: 28%; }
  .checkoutfond-row-1 .input-group-custom-2-container .input-group-custom-2 {
    width: 100%;
    margin: 0; }
    .checkoutfond-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group {
      margin: 0; }
      .checkoutfond-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group label {
        white-space: nowrap; }
      .checkoutfond-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group:first-child {
        width: 100%;
        padding-right: 0; }
        .checkoutfond-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group:first-child .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .checkoutfond-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group:nth-child(2) {
        width: 100%;
        max-width: 272px;
        margin-left: -1px;
        padding-left: 0; }
        .checkoutfond-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group:nth-child(2) .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }

.checkout-fond {
  max-width: 250px; }
  .checkout-fond .form-group {
    margin: 0;
    padding-left: 20px; }
    .checkout-fond .form-group label {
      white-space: nowrap; }
    .checkout-fond .form-group:first-child {
      margin-left: 0;
      width: 8%;
      padding-left: 15px; }
    .checkout-fond .form-group:nth-child(2) {
      width: 68%; }
    .checkout-fond .form-group:nth-child(3) {
      width: 20%; }
  .checkout-fond .input-group-custom-1-container {
    width: 36%;
    margin: 0; }
    .checkout-fond .input-group-custom-1-container .input-group-custom-1 {
      padding: 0;
      margin: 0; }
      .checkout-fond .input-group-custom-1-container .input-group-custom-1 .form-group {
        margin: 0; }
        .checkout-fond .input-group-custom-1-container .input-group-custom-1 .form-group label {
          white-space: nowrap; }
        .checkout-fond .input-group-custom-1-container .input-group-custom-1 .form-group:first-child {
          width: 27.4%;
          padding-right: 0; }
          .checkout-fond .input-group-custom-1-container .input-group-custom-1 .form-group:first-child .form-control {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0; }
        .checkout-fond .input-group-custom-1-container .input-group-custom-1 .form-group:nth-child(2) {
          width: 79%;
          margin-left: -1px;
          padding-left: 0; }
          .checkout-fond .input-group-custom-1-container .input-group-custom-1 .form-group:nth-child(2) .form-control {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0; }
  .checkout-fond .input-group-custom-2-container {
    display: flex;
    justify-content: flex-end; }
    .checkout-fond .input-group-custom-2-container .input-group-custom-2 {
      width: 100%;
      margin: 0; }
      .checkout-fond .input-group-custom-2-container .input-group-custom-2 .form-group {
        margin: 0; }
        .checkout-fond .input-group-custom-2-container .input-group-custom-2 .form-group label {
          white-space: nowrap; }
        .checkout-fond .input-group-custom-2-container .input-group-custom-2 .form-group:first-child {
          width: 100%;
          max-width: 130px;
          padding-right: 0; }
          .checkout-fond .input-group-custom-2-container .input-group-custom-2 .form-group:first-child .form-control {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0; }
        .checkout-fond .input-group-custom-2-container .input-group-custom-2 .form-group:nth-child(2) {
          width: 100%;
          max-width: 272px;
          margin-left: -1px;
          padding-left: 0; }
          .checkout-fond .input-group-custom-2-container .input-group-custom-2 .form-group:nth-child(2) .form-control {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0; }

.book-notice-row-1 .form-group {
  margin: 0;
  padding-left: 20px; }
  .book-notice-row-1 .form-group label {
    white-space: nowrap; }
  .book-notice-row-1 .form-group:first-child {
    margin-left: 0;
    width: 11%;
    padding-left: 15px; }
  .book-notice-row-1 .form-group:nth-child(2) {
    width: 13%; }
  .book-notice-row-1 .form-group:nth-child(4) {
    width: 11%; }
  .book-notice-row-1 .form-group:nth-child(5) {
    width: 11%; }
  .book-notice-row-1 .form-group:nth-child(6) {
    width: 11%; }
  .book-notice-row-1 .form-group:nth-child(7) {
    width: 11%; }

.book-notice-row-1 .input-group-custom-2-container {
  display: flex;
  justify-content: flex-end;
  width: 35%; }
  .book-notice-row-1 .input-group-custom-2-container .input-group-custom-2 {
    width: 100%;
    margin: 0; }
    .book-notice-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group {
      margin: 0; }
      .book-notice-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group label {
        white-space: nowrap; }
      .book-notice-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group:first-child {
        width: 100%;
        max-width: 130px;
        padding-right: 0; }
        .book-notice-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group:first-child .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .book-notice-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group:nth-child(2) {
        width: 100%;
        max-width: 272px;
        margin-left: -1px;
        padding-left: 0; }
        .book-notice-row-1 .input-group-custom-2-container .input-group-custom-2 .form-group:nth-child(2) .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }

.book-debit-row .form-group {
  margin: 0;
  padding-left: 20px; }
  .book-debit-row .form-group label {
    white-space: nowrap; }
  .book-debit-row .form-group:first-child {
    margin-left: 0;
    width: 10%;
    padding-left: 15px; }

.book-debit-row .input-group-custom-2-container {
  display: flex;
  justify-content: flex-end;
  width: 35%; }
  .book-debit-row .input-group-custom-2-container .input-group-custom-2 {
    width: 100%;
    margin: 0; }
    .book-debit-row .input-group-custom-2-container .input-group-custom-2 .form-group {
      margin: 0; }
      .book-debit-row .input-group-custom-2-container .input-group-custom-2 .form-group label {
        white-space: nowrap; }
      .book-debit-row .input-group-custom-2-container .input-group-custom-2 .form-group:first-child {
        width: 100%;
        max-width: 130px;
        padding-right: 0; }
        .book-debit-row .input-group-custom-2-container .input-group-custom-2 .form-group:first-child .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
      .book-debit-row .input-group-custom-2-container .input-group-custom-2 .form-group:nth-child(2) {
        width: 100%;
        max-width: 272px;
        margin-left: -1px;
        padding-left: 0; }
        .book-debit-row .input-group-custom-2-container .input-group-custom-2 .form-group:nth-child(2) .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }

.categories-manufacturers-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: unset; }
  .categories-manufacturers-row .input-group-custom-1-container {
    width: 32%;
    margin: 0; }
    .categories-manufacturers-row .input-group-custom-1-container:nth-child(2) .input-group-custom {
      justify-content: center; }
    .categories-manufacturers-row .input-group-custom-1-container .input-group-custom-1 {
      padding: 0;
      margin: 0; }
      .categories-manufacturers-row .input-group-custom-1-container .input-group-custom-1 .form-group {
        margin: 0; }
        .categories-manufacturers-row .input-group-custom-1-container .input-group-custom-1 .form-group label {
          white-space: nowrap; }
        .categories-manufacturers-row .input-group-custom-1-container .input-group-custom-1 .form-group:first-child {
          width: 36.4%;
          padding-right: 0; }
          .categories-manufacturers-row .input-group-custom-1-container .input-group-custom-1 .form-group:first-child .form-control {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0; }
        .categories-manufacturers-row .input-group-custom-1-container .input-group-custom-1 .form-group:nth-child(2) {
          width: 79%;
          margin-left: -1px;
          padding-left: 0; }
          .categories-manufacturers-row .input-group-custom-1-container .input-group-custom-1 .form-group:nth-child(2) .form-control {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0; }
    .categories-manufacturers-row .input-group-custom-1-container:nth-child(2) {
      width: 26%; }
      .categories-manufacturers-row .input-group-custom-1-container:nth-child(2) .input-group-custom-1 {
        padding: 0;
        margin: 0; }
        .categories-manufacturers-row .input-group-custom-1-container:nth-child(2) .input-group-custom-1 .form-group {
          margin: 0; }
          .categories-manufacturers-row .input-group-custom-1-container:nth-child(2) .input-group-custom-1 .form-group label {
            white-space: nowrap; }
          .categories-manufacturers-row .input-group-custom-1-container:nth-child(2) .input-group-custom-1 .form-group:first-child {
            width: 35.4%;
            padding-right: 0; }
            .categories-manufacturers-row .input-group-custom-1-container:nth-child(2) .input-group-custom-1 .form-group:first-child .form-control {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0; }
          .categories-manufacturers-row .input-group-custom-1-container:nth-child(2) .input-group-custom-1 .form-group:nth-child(2) {
            width: 59%;
            margin-left: -1px;
            padding-left: 0; }
            .categories-manufacturers-row .input-group-custom-1-container:nth-child(2) .input-group-custom-1 .form-group:nth-child(2) .form-control {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0; }
  .categories-manufacturers-row .form-group {
    padding-left: 15px;
    margin-bottom: 0; }
    .categories-manufacturers-row .form-group:last-child {
      width: 320px; }

.buyer-return-row .form-group:nth-child(2) {
  width: 55%; }

.buyer-return-row .form-group:nth-child(3) {
  width: 19%; }

.buyer-return-row .form-group:nth-child(4) {
  width: 18%; }

.buyer-return-row .form-group:nth-child(7) {
  width: 10%; }

.buyer-return-row .form-group:last-child {
  width: 13%; }

.buyer-return-row .input-group-custom {
  width: 10%; }
  .buyer-return-row .input-group-custom .form-group:first-child {
    width: 50%; }
  .buyer-return-row .input-group-custom .form-group:last-child {
    width: 50%; }

/*========== LOGIN ==========*/
.login-page {
  position: relative;
  height: 100vh; }
  .login-page-box-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media only screen and (max-width: 767px) {
      .login-page-box-container {
        width: 80%; } }
    .login-page-box-container .login-page-box {
      width: 600px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
      padding: 45px 0 55px 0;
      text-align: center;
      background-color: #fff;
      border-radius: 6px; }
      @media only screen and (max-width: 767px) {
        .login-page-box-container .login-page-box {
          width: 100%;
          padding: 50px 20px; } }
      .login-page-box-container .login-page-box .col-lg-8 {
        margin: auto; }
        .login-page-box-container .login-page-box .col-lg-8 .form-group:first-child input {
          margin-bottom: 20px; }
        .login-page-box-container .login-page-box .col-lg-8 .invalid-tooltip {
          top: 20%;
          left: 97%; }
      .login-page-box-container .login-page-box .logo {
        margin-bottom: 55px; }
        @media only screen and (max-width: 767px) {
          .login-page-box-container .login-page-box .logo {
            width: 50%; } }
      .login-page-box-container .login-page-box .form .form-group {
        margin-bottom: 0; }
        .login-page-box-container .login-page-box .form .form-group label {
          display: none; }
      .login-page-box-container .login-page-box .form input {
        max-width: 360px;
        width: 100%;
        height: 50px;
        margin: auto;
        border-radius: 6px;
        border: 1px solid #D2D6DA;
        padding-left: 20px;
        font-size: 13px;
        color: #818FA5;
        background-color: #fff !important; }
        .login-page-box-container .login-page-box .form input::placeholder {
          font-size: 13px;
          color: #818FA5; }
      .login-page-box-container .login-page-box .form button {
        max-width: 360px;
        width: 100%;
        height: 50px;
        margin-top: 25px;
        text-align: center;
        background-color: #49B848;
        border-radius: 6px;
        font-size: 13px;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
        border: none; }
        .login-page-box-container .login-page-box .form button:hover {
          cursor: pointer;
          transition: 0.3s ease-in-out;
          opacity: 0.94; }
        .login-page-box-container .login-page-box .form button:focus {
          outline: none;
          border: none;
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.04); }
      .login-page-box-container .login-page-box .form .toogle-switch {
        position: relative;
        left: 15px;
        padding-top: 15px; }
        .login-page-box-container .login-page-box .form .toogle-switch .switch-text {
          top: 3px; }
    .login-page-box-container .certificat {
      display: flex;
      justify-content: center;
      padding-top: 45px; }
      .login-page-box-container .certificat-content {
        padding-left: 15px; }
        .login-page-box-container .certificat-content h6 {
          font-size: 15px;
          color: #3A416F;
          margin-bottom: 0; }
        .login-page-box-container .certificat-content p {
          color: #818FA5; }
    .login-page-box-container .login-page-info-numbers-wrap {
      margin-top: 40px;
      width: 100%;
      padding: 0 20px; }
      .login-page-box-container .login-page-info-numbers-wrap .info-wrap h4 {
        font-size: 15px;
        font-weight: 600;
        line-height: 23px; }
      .login-page-box-container .login-page-info-numbers-wrap .info-wrap .numbers-wrap p {
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 0 !important; }

/*========== SKONTAJ ==========*/
.section-title {
  height: 54px;
  margin-bottom: 20px; }
  .section-title h1 {
    font-size: 27px;
    font-weight: 700;
    color: #3A416F;
    margin-bottom: 0; }
    @media only screen and (max-width: 767px) {
      .section-title h1 {
        font-size: 25px;
        padding-bottom: 10px; } }

.title-options-group-container {
  display: flex;
  align-items: center;
  position: relative;
  top: 70px; }
  @media only screen and (max-width: 767px) {
    .title-options-group-container {
      flex-direction: column;
      align-items: unset;
      top: 0; } }
  .title-options-group-container .title-options-group {
    padding: 10px;
    margin-left: 25px; }
    @media only screen and (max-width: 767px) {
      .title-options-group-container .title-options-group {
        margin-left: 0; } }

.title-options {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media only screen and (max-width: 767px) {
    .title-options {
      flex-direction: column;
      align-items: flex-start; } }
  .title-options .toogle-switch {
    position: relative;
    top: 2px; }
  .title-options-group {
    display: flex;
    height: 50px;
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    border: none;
    padding: 10px 5px; }
    @media only screen and (max-width: 767px) {
      .title-options-group {
        display: inline-flex; } }
    .title-options-group button {
      display: flex;
      align-items: center;
      position: relative;
      top: 1px;
      background-color: transparent;
      border: none;
      margin-left: 15px;
      font-size: 13px; }
      .title-options-group button:first-child {
        margin-left: 0; }
      .title-options-group button .option {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        background-color: #49B848;
        border-radius: 6px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06); }
        .title-options-group button .option:hover {
          transition: 0.2s all;
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
      .title-options-group button .green {
        background-color: #49B848; }
      .title-options-group button .blue {
        background-color: #138DB9; }
      .title-options-group button .red {
        background-color: #FD5C70; }
      .title-options-group button span {
        display: inline-block;
        font-size: 13px;
        color: #3A416F;
        padding-left: 10px; }
        @media only screen and (max-width: 767px) {
          .title-options-group button span {
            font-size: 11px; } }

.title-options-extrabutton {
  display: flex; }
  .title-options-extrabutton .posting-button {
    margin-right: 35px; }

.col-up .toogle-switch {
  position: relative;
  top: 6px;
  left: 30px; }

.show-unfinished {
  display: flex;
  align-items: center; }
  .show-unfinished label {
    font-size: 13px;
    color: #818FA5;
    margin-bottom: 0;
    margin-left: 5px;
    cursor: pointer; }
  .show-unfinished .form-check-input {
    margin-left: 0 !important;
    margin-top: 0 !important;
    position: relative;
    cursor: pointer; }

/*========== HEADER ==========*/
.header {
  margin-bottom: 25px; }
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between; }

.search-container {
  display: flex;
  width: 80%; }
  .search-container .search {
    position: relative;
    top: 2px;
    width: 100%;
    max-width: 1107px; }
    .search-container .search input {
      width: 100%;
      height: 42px;
      border-radius: 6px;
      border: 1px solid #D2D6DA;
      background-color: #fff;
      font-size: 13px;
      color: #818FA5;
      padding-left: 35px; }
      .search-container .search input ::placeholder {
        font-size: 13px;
        color: #818FA5; }
    .search-container .search svg {
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translate(0, -50%); }
    .search-container .search .dropdown-wrap {
      position: absolute;
      top: 45px;
      left: 0;
      right: 0;
      background-color: #fff;
      z-index: 99999;
      border-radius: 6px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
      padding-top: 10px;
      padding-bottom: 10px; }
      .search-container .search .dropdown-wrap .dropdown-item {
        font-size: 14px;
        cursor: pointer; }
      .search-container .search .dropdown-wrap .dropdown-item-active {
        background-color: #eee; }
  .search-container select {
    max-width: 103px;
    height: 42px;
    margin-left: 30px;
    margin-top: 2px; }

.profile-container {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  @media only screen and (max-width: 767px) {
    .profile-container {
      justify-content: center;
      padding-top: 15px; } }

.profile {
  display: flex;
  align-items: center; }
  .profile .icon {
    position: relative;
    left: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
    .profile .icon:hover {
      cursor: pointer; }
    .profile .icon .notification-number {
      position: absolute;
      top: 3px;
      right: 2.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #138DB9;
      font-size: 8px;
      font-weight: 600;
      color: #fff; }
  .profile .user-icon {
    margin-left: 30px; }
  .profile h6 {
    position: relative;
    left: -10px;
    margin-bottom: 0;
    font-size: 13px; }
    @media (min-width: 1200px) and (max-width: 1399px) {
      .profile h6 {
        font-size: 14px; } }
  .profile-settings {
    display: flex;
    align-items: center;
    padding-left: 40px; }
    @media (min-width: 1200px) and (max-width: 1810px) {
      .profile-settings {
        padding-left: 0; } }
    .profile-settings svg:hover {
      cursor: pointer; }
    .profile-settings .line {
      display: block;
      padding: 0 15px; }
      .profile-settings .line::before {
        content: '';
        position: relative;
        display: block;
        height: 20px;
        width: 1.5px;
        background-color: #818FA5; }

/*========== SIDEBAR ==========*/
.sidebar {
  position: fixed;
  max-width: 240px;
  width: 100%;
  height: 100vh;
  padding: 0 0 30px 0; }
  @media only screen and (max-width: 767px) {
    .sidebar {
      width: 100%;
      max-width: unset;
      top: 0;
      left: 0;
      height: auto; } }
  .sidebar .sidebar-scroll-area {
    height: 95vh;
    overflow-y: auto;
    position: relative;
    top: -21px; }
    @media only screen and (max-width: 767px) {
      .sidebar .sidebar-scroll-area {
        height: auto; } }
  .sidebar .sidebar-scroll-area::-webkit-scrollbar {
    width: 0; }
  .sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    margin-bottom: 30px; }
    @media only screen and (max-width: 767px) {
      .sidebar-header {
        padding: 0 35px;
        padding-top: 30px;
        background-color: #fff;
        z-index: 9999;
        opacity: 1; } }
    .sidebar-header .logo {
      max-width: 150px; }
      .sidebar-header .logo:hover {
        cursor: pointer; }
    .sidebar-header .menu-icon-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
      .sidebar-header .menu-icon-box:hover {
        cursor: pointer;
        opacity: 0.9;
        transition: 0.2s ease-in; }
  .sidebar-body {
    height: 72vh; }
    @media only screen and (max-width: 767px) {
      .sidebar-body {
        display: none; } }
    .sidebar-body .main-menu {
      padding-left: 0;
      padding-bottom: 60px; }
      .sidebar-body .main-menu li {
        list-style-type: none;
        margin-bottom: 10px; }
        .sidebar-body .main-menu li .main-menu-block {
          position: relative;
          margin-bottom: 10px; }
          .sidebar-body .main-menu li .main-menu-block:hover a {
            color: #3A416F;
            transition: 0.2s ease-in; }
        .sidebar-body .main-menu li .main-menu-block-no-hover-box {
          position: relative;
          margin-bottom: 10px; }
          .sidebar-body .main-menu li .main-menu-block-no-hover-box::before {
            content: '';
            position: absolute;
            top: 45%;
            right: 20px;
            transform: translate(0, -50%);
            width: 7px;
            height: 7px;
            background: transparent;
            cursor: pointer;
            border-top: 2.6px solid #818FA5;
            border-right: 2.6px solid #818FA5;
            transform: rotate(-46deg); }
          .sidebar-body .main-menu li .main-menu-block-no-hover-box:hover a {
            color: #3A416F;
            transition: 0.2s ease-in; }
        .sidebar-body .main-menu li .main-menu-block-without-arrow > li::after {
          content: unset; }
        .sidebar-body .main-menu li .main-menu-block-without-arrow > li:hover::marker {
          color: #3A416F; }
        .sidebar-body .main-menu li .main-menu-block-no-hover-box-active a {
          color: #3A416F !important;
          transition: 0.2s ease-in; }
        .sidebar-body .main-menu li .main-menu-block-without-arrow::before {
          content: unset; }
        .sidebar-body .main-menu li a {
          display: flex;
          align-items: center; }
          .sidebar-body .main-menu li a .icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            background-color: #fff;
            border-radius: 6px;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.14); }
            .sidebar-body .main-menu li a .icon-container svg {
              max-width: 15px; }
              .sidebar-body .main-menu li a .icon-container svg path, .sidebar-body .main-menu li a .icon-container svg line {
                stroke: #3A3F56; }
          .sidebar-body .main-menu li a .link-text {
            font-size: 13px;
            font-weight: 400;
            color: #3A416F;
            padding-left: 10px; }
        .sidebar-body .main-menu li .main-menu-dropdown {
          display: none; }
          .sidebar-body .main-menu li .main-menu-dropdown li::marker {
            color: #818FA5; }
          .sidebar-body .main-menu li .main-menu-dropdown > li {
            position: relative;
            padding-left: 30px; }
            .sidebar-body .main-menu li .main-menu-dropdown > li::after {
              content: '';
              position: absolute;
              top: 45%;
              right: 20px;
              transform: translate(0, -50%);
              width: 8px;
              height: 8px;
              background: transparent;
              border-top: 2px solid #818FA5;
              border-right: 2px solid #818FA5;
              transform: rotate(-46deg); }
            .sidebar-body .main-menu li .main-menu-dropdown > li:hover::marker {
              color: #3A416F; }
          .sidebar-body .main-menu li .main-menu-dropdown .rotate::after {
            top: 5px;
            transform: rotate(-226deg); }
          .sidebar-body .main-menu li .main-menu-dropdown li {
            list-style-type: disc; }
            .sidebar-body .main-menu li .main-menu-dropdown li::before {
              display: none; }
            .sidebar-body .main-menu li .main-menu-dropdown li a {
              font-size: 13px;
              font-weight: 600;
              color: #818FA5; }
              .sidebar-body .main-menu li .main-menu-dropdown li a:hover {
                color: #3A416F;
                transition: 0.2s ease-in;
                cursor: pointer; }
            .sidebar-body .main-menu li .main-menu-dropdown li .sub-dropdown {
              display: none;
              padding: 10px 0 0 20px;
              margin-bottom: 20px; }
              .sidebar-body .main-menu li .main-menu-dropdown li .sub-dropdown li {
                margin-bottom: 7px; }
                .sidebar-body .main-menu li .main-menu-dropdown li .sub-dropdown li:hover::marker {
                  color: #3A416F; }
            .sidebar-body .main-menu li .main-menu-dropdown li .open {
              display: block; }
        .sidebar-body .main-menu li .main-menu-dropdown-without-arrow > li::after {
          content: unset; }
        .sidebar-body .main-menu li .main-menu-dropdown-without-arrow > li:hover::marker {
          color: #3A416F; }
        .sidebar-body .main-menu li .open {
          display: block; }
      .sidebar-body .main-menu .section-divider {
        display: block;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        color: #818FA5;
        margin-top: 40px;
        margin-bottom: 25px; }
  .sidebar-footer {
    position: relative;
    height: 20vh;
    text-align: left; }
    @media only screen and (max-width: 767px) {
      .sidebar-footer {
        display: none; } }
    .sidebar-footer .fast-help {
      position: absolute;
      bottom: 5vh;
      background-image: linear-gradient(to left, #647591, #9aa6b8);
      border-radius: 10px;
      padding: 10px; }
      .sidebar-footer .fast-help svg {
        position: relative;
        left: -8px; }
      .sidebar-footer .fast-help h6 {
        font-size: 17px;
        font-weight: 600;
        color: #fff; }
      .sidebar-footer .fast-help p {
        font-size: 13px;
        color: #fff;
        line-height: 20px; }
      .sidebar-footer .fast-help button {
        margin-top: 20px;
        display: block;
        width: 100%;
        height: 40px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.14);
        font-size: 13px;
        font-weight: 600;
        color: #3A416F;
        text-transform: uppercase;
        border: none; }
        .sidebar-footer .fast-help button:hover {
          cursor: pointer;
          transition: 0.2s ease;
          opacity: 0.94; }

.sidebar-short {
  width: 100px; }
  .sidebar-short .sidebar-header {
    justify-content: center; }
    .sidebar-short .sidebar-header .logo {
      display: none; }
  .sidebar-short .sidebar-body .main-menu-block {
    text-align: center; }
    .sidebar-short .sidebar-body .main-menu-block::before {
      display: none; }
    .sidebar-short .sidebar-body .main-menu-block .link-text {
      display: none; }

.main-menu-block {
  padding: 10px; }
  .main-menu-block:hover {
    background-color: #fff;
    transition: 0.2s all;
    border-radius: 6px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
    cursor: pointer; }
    .main-menu-block:hover a .icon-container {
      background-color: #138DB9 !important;
      box-shadow: none; }
      .main-menu-block:hover a .icon-container svg path,
      .main-menu-block:hover a .icon-container svg line,
      .main-menu-block:hover a .icon-container svg g {
        stroke: #fff !important; }
    .main-menu-block:hover a .link-text {
      font-weight: 600 !important; }

.main-menu-block-active {
  background-color: #fff;
  transition: 0.2s all;
  border-radius: 6px;
  padding: 10px;
  cursor: pointer; }
  .main-menu-block-active a .icon-container {
    background-color: #138DB9 !important; }
    .main-menu-block-active a .icon-container svg path,
    .main-menu-block-active a .icon-container svg line,
    .main-menu-block-active a .icon-container svg g {
      stroke: #fff !important;
      line: #fff; }
  .main-menu-block-active a .link-text {
    font-weight: 600 !important; }

.sidebar-icon-box-active {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #138DB9;
  border-radius: 6px; }
  .sidebar-icon-box-active svg path,
  .sidebar-icon-box-active svg line,
  .sidebar-icon-box-active svg g {
    stroke: #fff !important;
    line: #fff; }

.sidebar-icon-box {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 6px; }

.rotate::before {
  top: 42% !important;
  transform: rotate(134deg) !important; }

a {
  text-decoration: none !important; }
  a:hover {
    text-decoration: none !important; }

/*========== REPORTS ==========*/
.report-row {
  display: flex;
  align-items: center;
  height: 60px;
  background-color: #138DB9;
  border-radius: 6px;
  margin-bottom: 20px;
  padding-left: 15px; }
  .report-row:hover {
    cursor: pointer;
    transition: 0.2s ease-in;
    opacity: 0.94; }
  .report-row svg {
    position: relative;
    top: 2px; }
  .report-row h6 {
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    margin-left: 15px;
    margin-bottom: 0; }

.test {
  padding-left: 150px; }

.sort-modules-wrap {
  background-color: #fff;
  padding: 25px 15px 10px 15px;
  border-radius: 6px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
  margin-left: 15px;
  margin-top: 20px; }
  .sort-modules-wrap .sort-modules-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
  .sort-modules-wrap h4 {
    font-size: 21px;
    font-weight: 600;
    color: #3A416F; }
  .sort-modules-wrap button {
    display: flex;
    align-items: center;
    position: relative;
    top: 2px;
    background-color: transparent;
    border: none;
    margin-left: 15px;
    font-size: 13px; }
    .sort-modules-wrap button:first-child {
      margin-left: 0; }
    .sort-modules-wrap button .option {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      background-color: #49B848;
      border-radius: 6px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06); }
      .sort-modules-wrap button .option:hover {
        transition: 0.2s all;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
    .sort-modules-wrap button .green {
      background-color: #49B848; }
    .sort-modules-wrap button .blue {
      background-color: #138DB9; }
    .sort-modules-wrap button .red {
      background-color: #FD5C70; }
    .sort-modules-wrap button span {
      display: inline-block;
      font-size: 13px;
      color: #3A416F;
      padding-left: 10px; }
      @media only screen and (max-width: 767px) {
        .sort-modules-wrap button span {
          font-size: 11px; } }
  .sort-modules-wrap .sort-list > li {
    list-style: none;
    display: block;
    cursor: grab; }
    .sort-modules-wrap .sort-list > li h6 {
      display: flex;
      align-items: center;
      background-color: #F8F9FA;
      height: 34px;
      border-radius: 10px;
      padding-left: 25px; }
      .sort-modules-wrap .sort-list > li h6 svg {
        position: relative;
        left: -8px; }
    .sort-modules-wrap .sort-list > li .sort-list > li {
      list-style: none;
      display: flex;
      align-items: center;
      padding-left: 15px;
      height: 34px;
      background-color: #F8F9FA;
      margin-bottom: 12px;
      border-radius: 10px; }
      .sort-modules-wrap .sort-list > li .sort-list > li h6 {
        color: #3a3f56;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 0;
        padding-left: 15px; }
        .sort-modules-wrap .sort-list > li .sort-list > li h6 svg {
          position: relative;
          left: -10px; }
  .sort-modules-wrap body > li {
    border-radius: 10px;
    color: #fff;
    display: flex;
    flex-direction: column;
    -webkit-box-orient: vertical;
    justify-content: center;
    list-style: none;
    background-color: transparent !important; }
    .sort-modules-wrap body > li > div > .form-group > div {
      display: none; }
    .sort-modules-wrap body > li > div > .form-group {
      display: block; }
      .sort-modules-wrap body > li > div > .form-group > div {
        display: none;
        color: #3A3F56; }
    .sort-modules-wrap body > li h6 {
      display: flex !important;
      align-items: center;
      color: #3a3f56;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 10px !important;
      padding-left: 15px;
      background-color: #138DB9 !important;
      padding: 18px 0 18px 15px;
      border-radius: 10px;
      color: #fff; }
      .sort-modules-wrap body > li h6 svg {
        position: relative;
        left: -5px !important; }
    .sort-modules-wrap body > li > .sort-list li {
      margin-bottom: 15px; }
    .sort-modules-wrap body > li h6 {
      padding-left: 20px;
      margin-bottom: 0; }
      .sort-modules-wrap body > li h6 svg {
        position: relative;
        top: -2px;
        left: -2px; }
        .sort-modules-wrap body > li h6 svg rect {
          fill: #fff !important; }

.padding-left-5 {
  padding-left: 5px !important; }

.padding-left-20 {
  padding-left: 20px !important; }

.list-builder-filters-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .list-builder-filters-wrap .list-builder-filters {
    display: flex;
    align-items: flex-end;
    width: 70%;
    margin-bottom: 15px; }
    .list-builder-filters-wrap .list-builder-filters .field-strap-wrap {
      margin-right: 10px; }
      .list-builder-filters-wrap .list-builder-filters .field-strap-wrap:first-child {
        width: 18%; }
      .list-builder-filters-wrap .list-builder-filters .field-strap-wrap:nth-child(2) {
        width: 18%; }
      .list-builder-filters-wrap .list-builder-filters .field-strap-wrap:nth-child(3) {
        width: 45%; }
      .list-builder-filters-wrap .list-builder-filters .field-strap-wrap:nth-child(4) {
        width: 25%; }
      .list-builder-filters-wrap .list-builder-filters .field-strap-wrap label {
        font-size: 13px;
        font-weight: 600;
        color: #3A416F; }
    .list-builder-filters-wrap .list-builder-filters .merged-fields {
      display: flex;
      align-items: center;
      position: relative; }
      .list-builder-filters-wrap .list-builder-filters .merged-fields input:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      .list-builder-filters-wrap .list-builder-filters .merged-fields input:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -1px; }
  .list-builder-filters-wrap .list-builder-filters-nivelation {
    display: flex;
    align-items: center;
    width: 95%;
    margin-bottom: 15px; }
    .list-builder-filters-wrap .list-builder-filters-nivelation .field-strap-wrap {
      margin-right: 10px; }
      .list-builder-filters-wrap .list-builder-filters-nivelation .field-strap-wrap:first-child {
        width: 100%;
        max-width: 120px; }
      .list-builder-filters-wrap .list-builder-filters-nivelation .field-strap-wrap:nth-child(2) {
        width: 100%;
        max-width: 120px; }
      .list-builder-filters-wrap .list-builder-filters-nivelation .field-strap-wrap:nth-child(3) {
        width: 100%;
        max-width: 120px; }
      .list-builder-filters-wrap .list-builder-filters-nivelation .field-strap-wrap:nth-child(4) {
        width: 100%;
        max-width: 159px; }
      .list-builder-filters-wrap .list-builder-filters-nivelation .field-strap-wrap:nth-child(5) {
        width: 100%;
        max-width: 230px; }
      .list-builder-filters-wrap .list-builder-filters-nivelation .field-strap-wrap:nth-child(6) {
        width: 100%;
        max-width: 168px; }
      .list-builder-filters-wrap .list-builder-filters-nivelation .field-strap-wrap:nth-child(7) {
        width: 100%;
        max-width: 120px; }
      .list-builder-filters-wrap .list-builder-filters-nivelation .field-strap-wrap:last-child {
        width: 100%;
        max-width: 330px; }
  .list-builder-filters-wrap .clear-filters {
    position: relative;
    top: -7.5px;
    font-size: 13px;
    font-weight: 400;
    color: #818FA5;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: pre; }
    .list-builder-filters-wrap .clear-filters a {
      display: flex;
      align-items: center; }
      .list-builder-filters-wrap .clear-filters a span {
        font-size: 22px;
        margin-right: 5px; }

.list-builder-filters-wrap-output {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .list-builder-filters-wrap-output .list-builder-filters {
    display: flex;
    align-items: center;
    width: 90%; }
    .list-builder-filters-wrap-output .list-builder-filters .field-strap-wrap {
      margin-right: 10px; }
      .list-builder-filters-wrap-output .list-builder-filters .field-strap-wrap:first-child {
        width: 12%; }
      .list-builder-filters-wrap-output .list-builder-filters .field-strap-wrap:nth-child(2) {
        width: 12%; }
      .list-builder-filters-wrap-output .list-builder-filters .field-strap-wrap:nth-child(3) {
        width: 30%; }
      .list-builder-filters-wrap-output .list-builder-filters .field-strap-wrap:nth-child(4) {
        width: 15%; }
      .list-builder-filters-wrap-output .list-builder-filters .field-strap-wrap:nth-child(5) {
        width: 10%; }
      .list-builder-filters-wrap-output .list-builder-filters .field-strap-wrap:nth-child(6) {
        width: 10%; }
      .list-builder-filters-wrap-output .list-builder-filters .field-strap-wrap:nth-child(7) {
        width: 11%; }
  .list-builder-filters-wrap-output .clear-filters {
    position: relative;
    top: -7.5px;
    font-size: 13px;
    font-weight: 400;
    color: #818FA5;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: pre; }
    .list-builder-filters-wrap-output .clear-filters a {
      display: flex;
      align-items: center; }
      .list-builder-filters-wrap-output .clear-filters a span {
        font-size: 22px;
        margin-right: 5px; }

.field-strap-wrap input {
  border-radius: 6px;
  outline: none;
  box-shadow: none;
  border: 1px solid #D2D6DA;
  font-size: 13px;
  font-weight: 400;
  height: 42px; }
  .field-strap-wrap input:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid #D2D6DA !important; }

.field-strap-wrap .form-control {
  font-size: 13px;
  font-weight: 400;
  height: 42px; }

.field-strap-wrap .form-group {
  margin-bottom: 0 !important;
  width: 200px; }
  .field-strap-wrap .form-group .form-control {
    font-size: 13px;
    font-weight: 400;
    width: 100% !important;
    height: 42px; }

.select-with-search-list-wrap {
  height: auto;
  width: 100%;
  background-color: #fff;
  border: 1px solid #E6EDF5;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
  position: absolute;
  left: 0;
  top: 73px;
  z-index: 9999;
  border-radius: 6px;
  padding: 8px 0;
  padding-bottom: 5px;
  max-height: 180px;
  overflow: scroll; }
  .select-with-search-list-wrap .select-with-search-list-item {
    padding: 2px 5px;
    font-size: 13px;
    font-weight: 600;
    white-space: nowrap;
    height: 24px;
    cursor: pointer; }
    .select-with-search-list-wrap .select-with-search-list-item:hover {
      background-color: #E6EDF5; }
    .select-with-search-list-wrap .select-with-search-list-item-disabled {
      background-color: #BCC3CF;
      cursor: not-allowed; }
  .select-with-search-list-wrap::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  .select-with-search-list-wrap::-webkit-scrollbar-thumb {
    background-color: #818FA5;
    border-radius: 6px; }
  .select-with-search-list-wrap .selected-item {
    background-color: #E6EDF5; }

.toogle-top-0 .toogle-switch {
  top: 0 !important; }

.input-type-text {
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */ }
  .input-type-text input::-webkit-outer-spin-button,
  .input-type-text input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .input-type-text input[type=number] {
    -moz-appearance: textfield; }

.dependent-costs-wrap {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll; }
  .dependent-costs-wrap::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  .dependent-costs-wrap::-webkit-scrollbar-thumb {
    background-color: #818FA5;
    border-radius: 6px; }
  .dependent-costs-wrap .dependent-cost {
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 10px; }
    .dependent-costs-wrap .dependent-cost .form-group {
      width: 40%;
      display: flex;
      flex-direction: column;
      margin-right: 10px;
      align-items: flex-start;
      margin-bottom: 0; }
      .dependent-costs-wrap .dependent-cost .form-group label {
        font-size: 14px; }
    .dependent-costs-wrap .dependent-cost .remove-item-button {
      width: 13%;
      display: flex;
      justify-content: flex-end; }

.file-upload-wrap {
  position: relative; }
  .file-upload-wrap input {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer; }
  .file-upload-wrap .icon {
    background-color: #fff;
    border-radius: 6px;
    width: 30px;
    height: 30px;
    margin-right: 10px; }
    .file-upload-wrap .icon svg path {
      fill: #3A3F56; }
  .file-upload-wrap button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;
    cursor: pointer;
    background-color: #138DB9;
    font-size: 15px;
    font-weight: 600; }
  .file-upload-wrap .error {
    color: red; }

.info-modal .message {
  font-size: 16px; }

.info-modal-button {
  max-width: 100%;
  width: unset !important; }

.invalid-tooltip-wrap-date {
  position: absolute;
  top: 50%;
  left: 100%; }
  .invalid-tooltip-wrap-date .invalid-tooltip {
    left: -100%;
    height: auto; }

.cursor-pointer {
  cursor: pointer !important; }

.row-pl-fix > .row {
  padding-left: 15px; }
  .row-pl-fix > .row .custom-input-row {
    padding-right: 0; }

.dropdown-select-field {
  position: relative; }
  .dropdown-select-field .dropdown-select {
    margin-bottom: 0 !important;
    border: none !important;
    position: relative;
    font-size: 15px !important;
    padding-right: 30px;
    color: #3A416F !important;
    white-space: nowrap;
    cursor: pointer; }
    .dropdown-select-field .dropdown-select::after {
      content: '';
      display: block;
      position: absolute;
      top: 5px;
      right: 15px;
      width: 8px;
      height: 8px;
      background-color: transparent;
      border-top: 1px solid #3A416F;
      border-right: 1px solid #3A416F;
      transform: rotate(133deg); }
  .dropdown-select-field .dropdown-items-wrap {
    position: absolute;
    top: 35px;
    background-color: #fff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    right: 8px;
    padding: 10px 0;
    border-radius: 10px;
    z-index: 9999; }
    .dropdown-select-field .dropdown-items-wrap::before {
      content: ' ';
      position: absolute;
      right: 0px;
      top: -13px;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 20px solid #fff;
      clear: both; }
    .dropdown-select-field .dropdown-items-wrap li {
      list-style-type: none;
      /* Remove bullets */
      padding: 0;
      /* Remove padding */
      margin: 0;
      /* Remove margins */
      white-space: nowrap;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      padding: 0 25px;
      font-size: 15px !important;
      color: #3A416F !important;
      cursor: pointer; }
      .dropdown-select-field .dropdown-items-wrap li:hover {
        background-color: #eee; }
    .dropdown-select-field .dropdown-items-wrap .active-item {
      background-color: #eee; }

.form-builder-h4 {
  font-size: 21px;
  font-weight: 600;
  color: #3A416F;
  margin-bottom: 20px;
  margin-left: 15px; }

.blue-button-outline {
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 50px;
  outline: none;
  border: none;
  background-color: #fff;
  border: 1px solid #138DB9;
  border-radius: 6px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
  @media (min-width: 1200px) and (max-width: 1399px) {
    .blue-button-outline {
      padding: 0 10px; } }
  .blue-button-outline:nth-child(2) {
    margin: 0 20px; }
  .blue-button-outline .box {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
  .blue-button-outline .box-red {
    background-color: #FD5C70; }
  .blue-button-outline .box-blue {
    background-color: #138DB9; }
  .blue-button-outline svg path, .blue-button-outline svg rect {
    fill: #fff; }
  .blue-button-outline p {
    margin-bottom: 0;
    padding-left: 10px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    color: #138DB9; }
    @media (min-width: 1200px) and (max-width: 1399px) {
      .blue-button-outline p {
        font-size: 11px; } }
  .blue-button-outline:hover {
    background-color: rgba(19, 141, 185, 0.05); }

.blue-button {
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 50px;
  outline: none;
  border: none;
  background-color: #138DB9;
  border-radius: 6px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
  @media (min-width: 1200px) and (max-width: 1399px) {
    .blue-button {
      padding: 0 10px; } }
  .blue-button:nth-child(2) {
    margin: 0 20px; }
  .blue-button .box {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
  .blue-button .box-red {
    background-color: #FD5C70; }
  .blue-button .box-blue {
    background-color: #fff; }
  .blue-button p {
    margin-bottom: 0;
    padding-left: 10px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff; }
    @media (min-width: 1200px) and (max-width: 1399px) {
      .blue-button p {
        font-size: 11px; } }
  .blue-button:hover {
    background-color: rgba(19, 141, 185, 0.9); }

.blue-button-disabeld {
  opacity: 0.5;
  background-color: #138DB9 !important; }
  .blue-button-disabeld:hover {
    background-color: #138db9 !important; }

.width-100 {
  width: 100% !important; }

.green-line {
  background-color: #49B848;
  border-radius: 6px;
  height: 20px;
  width: 100% !important; }

.red-line {
  background-color: #FD5C70;
  border-radius: 6px;
  height: 20px;
  width: 100% !important; }

.modal-wrap-input-crates {
  width: 660px !important; }
  .modal-wrap-input-crates .input-crates-wrap {
    padding: 24px 20px; }
    .modal-wrap-input-crates .input-crates-wrap .section-title {
      margin-bottom: 0;
      height: unset; }
      .modal-wrap-input-crates .input-crates-wrap .section-title h4 {
        font-size: 21px;
        font-weight: 600;
        color: #3A416F;
        text-align: left;
        margin-bottom: 0; }
    .modal-wrap-input-crates .input-crates-wrap h6 {
      font-size: 13px;
      font-weight: 600;
      color: #818FA5;
      text-align: left;
      margin-bottom: 0;
      margin-top: 27px; }
    .modal-wrap-input-crates .input-crates-wrap .codes-wrap {
      margin-top: 10px;
      max-height: 350px;
      overflow-y: auto;
      padding-right: 15px; }
      .modal-wrap-input-crates .input-crates-wrap .codes-wrap .code-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 15px;
        font-weight: 400;
        color: #3A416F;
        margin-bottom: 9px; }
        .modal-wrap-input-crates .input-crates-wrap .codes-wrap .code-item svg {
          cursor: pointer; }
    .modal-wrap-input-crates .input-crates-wrap .codes-wrap::-webkit-scrollbar {
      width: 10px; }
    .modal-wrap-input-crates .input-crates-wrap .codes-wrap::-webkit-scrollbar-thumb {
      background-color: #818FA5;
      border-radius: 6px; }
    .modal-wrap-input-crates .input-crates-wrap .inputs-wrap {
      display: flex;
      flex-direction: column;
      margin-top: 14px; }
      .modal-wrap-input-crates .input-crates-wrap .inputs-wrap .input-field-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 14px; }
        .modal-wrap-input-crates .input-crates-wrap .inputs-wrap .input-field-wrap label {
          font-size: 13px;
          font-weight: 600;
          color: #3A416F;
          margin-bottom: 5px; }
        .modal-wrap-input-crates .input-crates-wrap .inputs-wrap .input-field-wrap .form-control {
          border-radius: 6px;
          height: 42px;
          font-size: 14px; }
        .modal-wrap-input-crates .input-crates-wrap .inputs-wrap .input-field-wrap .input-wrap {
          display: flex; }
          .modal-wrap-input-crates .input-crates-wrap .inputs-wrap .input-field-wrap .input-wrap .form-control:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0; }
          .modal-wrap-input-crates .input-crates-wrap .inputs-wrap .input-field-wrap .input-wrap .form-control:last-child {
            margin-left: -5px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0; }
        .modal-wrap-input-crates .input-crates-wrap .inputs-wrap .input-field-wrap .form-group {
          margin-bottom: 0;
          width: 100%; }
          .modal-wrap-input-crates .input-crates-wrap .inputs-wrap .input-field-wrap .form-group div {
            display: flex;
            flex-direction: column;
            align-items: flex-start; }
            .modal-wrap-input-crates .input-crates-wrap .inputs-wrap .input-field-wrap .form-group div .select-with-search-list-item {
              width: 100%; }
      .modal-wrap-input-crates .input-crates-wrap .inputs-wrap .button-wrap {
        margin-top: 46px; }
        .modal-wrap-input-crates .input-crates-wrap .inputs-wrap .button-wrap button {
          padding-left: 30px;
          padding-right: 30px; }

.panel-backgound {
  background-color: #fff;
  border-radius: 6px;
  padding: 20px;
  padding-bottom: 0; }

.modal-wrap-cold-chain-preview {
  width: 1300px; }
  .modal-wrap-cold-chain-preview .modal-header {
    display: flex;
    justify-content: space-between;
    border: none !important; }
  .modal-wrap-cold-chain-preview .modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .modal-wrap-cold-chain-preview .title {
    font-size: 27px;
    color: #3A416F;
    font-weight: 700; }
  .modal-wrap-cold-chain-preview .print-button {
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: 50px;
    outline: none;
    border: none;
    background-color: #EBECF0;
    border-radius: 6px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
    color: #3A416F;
    font-size: 13px;
    font-weight: 400; }
    @media (min-width: 1200px) and (max-width: 1399px) {
      .modal-wrap-cold-chain-preview .print-button {
        padding: 0 10px; } }
    .modal-wrap-cold-chain-preview .print-button:nth-child(2) {
      margin: 0 20px; }
    .modal-wrap-cold-chain-preview .print-button .svg-box {
      background-color: #138DB9;
      border-radius: 6px;
      padding: 5px 6px;
      margin-right: 10px; }

.lock-modal {
  position: absolute;
  top: 40px;
  width: 250px;
  margin-right: 10px;
  background-color: white;
  padding: 5px; }
  .lock-modal button {
    background-color: transparent;
    border: none; }

.display-flex-center {
  display: flex;
  align-items: center; }

.list-builder-drop-down-wrap {
  position: absolute; }
  .list-builder-drop-down-wrap .drop-down-wrap {
    position: absolute;
    top: 20px;
    right: 0;
    border-radius: 6px;
    background-color: #F8F9FA;
    border: 1px solid #909CB0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    z-index: 9999; }
    .list-builder-drop-down-wrap .drop-down-wrap table {
      width: 100%; }
      .list-builder-drop-down-wrap .drop-down-wrap table tr th {
        font-size: 13px;
        font-weight: 600;
        color: #818FA5;
        padding: 9px 20px;
        text-align: left; }
      .list-builder-drop-down-wrap .drop-down-wrap table tr td {
        font-size: 14px;
        font-weight: 400;
        color: #3A416F !important;
        text-align: left;
        padding: 10px 20px;
        white-space: nowrap; }

.table-responsive {
  overflow: unset !important; }

.table-container-scroll {
  overflow: auto !important;
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .table-container-scroll::-webkit-scrollbar {
    width: 10px;
    height: 12px; }
  .table-container-scroll::-webkit-scrollbar-track {
    background: #EBECF0;
    border-radius: 6px; }
  .table-container-scroll::-webkit-scrollbar-thumb {
    background: #818FA5;
    border-radius: 6px; }
  .table-container-scroll::-webkit-scrollbar-thumb:hover {
    background: #818FA5; }

.unbook-ko-list .list-builder-wrap .table-responsive tbody tr {
  display: flex; }
  .unbook-ko-list .list-builder-wrap .table-responsive tbody tr td:first-child {
    width: 15%;
    display: block; }
  .unbook-ko-list .list-builder-wrap .table-responsive tbody tr td:nth-child(2) {
    width: 20%; }
  .unbook-ko-list .list-builder-wrap .table-responsive tbody tr td:nth-child(3) {
    width: 20%; }
  .unbook-ko-list .list-builder-wrap .table-responsive tbody tr td:nth-child(4) {
    width: 15%; }
  .unbook-ko-list .list-builder-wrap .table-responsive tbody tr td:nth-child(5) {
    width: 15%; }
  .unbook-ko-list .list-builder-wrap .table-responsive tbody tr td:nth-child(6) {
    width: 15%; }

.unbook-ko-list .table tr:first-child {
  border-bottom: none; }

.check-box-wrap {
  display: flex;
  align-items: center; }
  .check-box-wrap .checkbox-wrap-v2 {
    margin-right: 30px;
    white-space: nowrap; }
    .check-box-wrap .checkbox-wrap-v2 .checkbox-v2 {
      background-color: transparent !important;
      border: 1px solid #D2D6DA;
      border-radius: 6px; }
    .check-box-wrap .checkbox-wrap-v2 .checkbox-v2-checked {
      background-color: transparent !important; }
      .check-box-wrap .checkbox-wrap-v2 .checkbox-v2-checked::after {
        content: '';
        display: block;
        position: relative;
        margin-top: -1px;
        width: 5px;
        height: 9px;
        border-bottom: 2px solid #818FA5;
        border-right: 2px solid #818FA5;
        transform: rotate(45deg); }
    .check-box-wrap .checkbox-wrap-v2 span {
      font-size: 15px !important; }

.field-wrap-width-unset .field-strap-wrap {
  width: unset !important; }

.td-color-dark-black {
  color: #3A416F !important; }

.loader-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.7;
  z-index: 999;
  background-color: #fff !important; }
  .loader-wrap .loader-text {
    font-weight: 600;
    font-size: 16px;
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: center; }

.date-input-width-150 {
  width: 150px !important; }
  .date-input-width-150 .form-control {
    width: 150px !important; }

.blue-box-info-text-wrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 15px; }
  .blue-box-info-text-wrap .blue-box-info-text {
    background-color: #138DB9;
    border-radius: 6px;
    padding: 15px 25px; }
    .blue-box-info-text-wrap .blue-box-info-text h6 {
      font-size: 13px;
      color: #fff;
      font-weight: 600;
      margin-bottom: 5px;
      white-space: nowrap; }
    .blue-box-info-text-wrap .blue-box-info-text h4 {
      font-size: 19px;
      color: #fff;
      font-weight: 700;
      margin-bottom: 0px; }

.modal-body-list-builder-wrap {
  max-height: 400px;
  overflow-y: auto; }

.list-builder-sub-items-wrap {
  padding-bottom: 10px;
  background-color: #F8F9FA;
  width: 100%; }

.analysis-col-1 {
  margin-top: 3px; }
  .analysis-col-1 .checkbox-wrap-v2 {
    margin-bottom: 8px; }
  .analysis-col-1 .checkbox-list .col-lg-6:nth-child(even) {
    display: flex;
    justify-content: flex-end; }
    .analysis-col-1 .checkbox-list .col-lg-6:nth-child(even) .checkbox-wrap-v2 {
      margin-right: 0; }
      .analysis-col-1 .checkbox-list .col-lg-6:nth-child(even) .checkbox-wrap-v2 span {
        width: 100px; }
  .analysis-col-1 .checkbox-list .textarea-custom textarea {
    width: 100%;
    max-width: 320px;
    height: 175px;
    margin-bottom: 10px; }
  .analysis-col-1 .checkbox-list .select-clasic {
    width: 100%;
    max-width: 320px;
    margin-top: 15px; }
    .analysis-col-1 .checkbox-list .select-clasic label {
      font-size: 13px;
      color: #3A416F;
      font-weight: 600; }
    .analysis-col-1 .checkbox-list .select-clasic select {
      background-position-y: 18px; }

.analysis-col-2 .field-strap-wrap {
  margin-bottom: 5px; }
  .analysis-col-2 .field-strap-wrap label {
    font-size: 13px;
    color: #3A416F;
    font-weight: 600; }

.analysis-buttons-container {
  width: 87%;
  display: flex;
  justify-content: space-between; }
  .analysis-buttons-container .blue-button:nth-child(2) {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .analysis-buttons-container .blue-button {
    margin-bottom: 15px !important; }
  .analysis-buttons-container h6 {
    font-size: 13px;
    color: #3A416F;
    font-weight: 600;
    text-transform: uppercase; }
  .analysis-buttons-container .blue-button-outline {
    margin: 0;
    margin-bottom: 15px;
    background-color: #138DB9; }
    .analysis-buttons-container .blue-button-outline p {
      color: #fff; }

.list-builder-item-href {
  padding: 3px; }
  .list-builder-item-href p {
    text-align: center;
    margin-bottom: 0;
    cursor: pointer; }
  .list-builder-item-href:hover {
    background-color: #dee2e6;
    border-radius: 6px; }

.delete-modal .message {
  font-size: 17px; }

.tooltip-div {
  position: relative;
  cursor: default !important; }
  .tooltip-div .tooltip-text {
    position: absolute;
    bottom: 110%;
    left: 0;
    color: #fff;
    text-align: center;
    font-size: 12px;
    padding: 5px 10px;
    background-color: #3A3F56;
    border-radius: 6px;
    visibility: hidden;
    width: auto;
    white-space: nowrap;
    z-index: 9; }
    .tooltip-div .tooltip-text::before {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      transform: rotate(136deg);
      background-color: #3A3F56;
      bottom: -3px;
      left: 7px;
      z-index: -1; }
  .tooltip-div:hover .tooltip-text {
    visibility: visible; }

.file-field-without-margin-bottom {
  margin-bottom: 0 !important;
  top: 30px; }
  .file-field-without-margin-bottom input {
    margin-bottom: 0 !important; }

.input-mb-small .form-group input {
  margin-bottom: 0 !important; }

.supplier-manufacturers-row {
  display: flex;
  justify-content: unset; }
  .supplier-manufacturers-row .input-group-custom-1-container {
    width: 32%;
    margin: 0; }
    .supplier-manufacturers-row .input-group-custom-1-container:nth-child(2) .input-group-custom {
      justify-content: center; }
    .supplier-manufacturers-row .input-group-custom-1-container .input-group-custom-1 {
      padding: 0;
      margin: 0; }
      .supplier-manufacturers-row .input-group-custom-1-container .input-group-custom-1 .form-group {
        margin: 0; }
        .supplier-manufacturers-row .input-group-custom-1-container .input-group-custom-1 .form-group label {
          white-space: nowrap; }
        .supplier-manufacturers-row .input-group-custom-1-container .input-group-custom-1 .form-group:first-child {
          width: 36.4%;
          padding-right: 0; }
          .supplier-manufacturers-row .input-group-custom-1-container .input-group-custom-1 .form-group:first-child .form-control {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0; }
        .supplier-manufacturers-row .input-group-custom-1-container .input-group-custom-1 .form-group:nth-child(2) {
          width: 79%;
          margin-left: -1px;
          padding-left: 0; }
          .supplier-manufacturers-row .input-group-custom-1-container .input-group-custom-1 .form-group:nth-child(2) .form-control {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0; }
    .supplier-manufacturers-row .input-group-custom-1-container:nth-child(2) {
      width: 26%; }
      .supplier-manufacturers-row .input-group-custom-1-container:nth-child(2) .input-group-custom-1 {
        padding: 0;
        margin: 0; }
        .supplier-manufacturers-row .input-group-custom-1-container:nth-child(2) .input-group-custom-1 .form-group {
          margin: 0; }
          .supplier-manufacturers-row .input-group-custom-1-container:nth-child(2) .input-group-custom-1 .form-group label {
            white-space: nowrap; }
          .supplier-manufacturers-row .input-group-custom-1-container:nth-child(2) .input-group-custom-1 .form-group:first-child {
            width: 35.4%;
            padding-right: 0; }
            .supplier-manufacturers-row .input-group-custom-1-container:nth-child(2) .input-group-custom-1 .form-group:first-child .form-control {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0; }
          .supplier-manufacturers-row .input-group-custom-1-container:nth-child(2) .input-group-custom-1 .form-group:nth-child(2) {
            width: 59%;
            margin-left: -1px;
            padding-left: 0; }
            .supplier-manufacturers-row .input-group-custom-1-container:nth-child(2) .input-group-custom-1 .form-group:nth-child(2) .form-control {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0; }
  .supplier-manufacturers-row .form-group {
    padding-left: 15px;
    margin-bottom: 0; }
    .supplier-manufacturers-row .form-group:last-child {
      width: 320px; }

.button-centered-fix .form-group {
  margin-bottom: 0; }

.btn-secondary.disabled {
  background-color: unset; }

.module-endpoints-div {
  background-color: transparent;
  border-radius: 6px;
  font-size: 10px;
  font-weight: 600;
  padding: 2px 7px;
  color: #3A3F56;
  border: 1px solid #3A3F56;
  margin-right: 5px; }

.endpoints-modal-preview-wrap {
  overflow: hidden;
  width: 700px !important; }
  .endpoints-modal-preview-wrap .modal-title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; }
  .endpoints-modal-preview-wrap .endpoints-wrap {
    min-height: 100px;
    max-height: 350px;
    height: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .endpoints-modal-preview-wrap .endpoints-wrap .endpoint-item {
      display: flex;
      align-items: center;
      margin-top: 5px;
      margin-bottom: 5px; }
      .endpoints-modal-preview-wrap .endpoints-wrap .endpoint-item .endpoint-method {
        font-size: 16px;
        font-weight: 700;
        min-width: 70px;
        text-align: left; }
      .endpoints-modal-preview-wrap .endpoints-wrap .endpoint-item .method-POST {
        color: orange; }
      .endpoints-modal-preview-wrap .endpoints-wrap .endpoint-item .method-PUT {
        color: purple; }
      .endpoints-modal-preview-wrap .endpoints-wrap .endpoint-item .method-DELETE {
        color: red; }
      .endpoints-modal-preview-wrap .endpoints-wrap .endpoint-item .method-GET {
        color: green; }
      .endpoints-modal-preview-wrap .endpoints-wrap .endpoint-item .endpoint-path {
        font-size: 16px;
        font-weight: 400; }

.header-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 9999; }
  .header-container .header-item-wrap {
    background-color: #138DB9;
    z-index: 9999;
    border-radius: 10px;
    padding: 14px 20px;
    display: flex;
    align-items: center; }
    .header-container .header-item-wrap .cart-wrap {
      display: flex;
      align-items: center; }
      .header-container .header-item-wrap .cart-wrap .cart-info {
        margin-left: 15px; }
        .header-container .header-item-wrap .cart-wrap .cart-info h5 {
          font-size: 13px;
          color: #fff;
          font-weight: 400;
          margin-bottom: 0px; }
        .header-container .header-item-wrap .cart-wrap .cart-info h4 {
          font-size: 19px;
          color: #fff;
          font-weight: 600;
          margin-bottom: 0px; }
  .header-container .profile {
    margin-left: 42px; }
    .header-container .profile .profile-name {
      margin-left: 25px;
      width: auto;
      max-width: 140px; }
      .header-container .profile .profile-name h6 {
        font-size: 13px;
        font-weight: 400;
        line-height: 17px;
        color: #fff;
        max-width: 140px; }
    .header-container .profile .profile-settings .dropdown-wrap {
      position: relative; }
      .header-container .profile .profile-settings .dropdown-wrap svg rect {
        fill: #fff; }
      .header-container .profile .profile-settings .dropdown-wrap .dropdown {
        position: absolute;
        top: 100%;
        right: -7px;
        padding: 12px 0;
        background-color: #fff;
        border-radius: 6px;
        min-width: 150px;
        max-width: 250px;
        width: auto;
        z-index: 999;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06); }
        .header-container .profile .profile-settings .dropdown-wrap .dropdown:before {
          content: '';
          display: block;
          position: absolute;
          top: -5px;
          right: 6px;
          width: 12px;
          height: 12px;
          background-color: #fff;
          transform: rotate(46deg);
          z-index: 99; }
        .header-container .profile .profile-settings .dropdown-wrap .dropdown .dropdown-list-item {
          font-size: 13px;
          padding: 2px 10px;
          padding-left: 15px;
          display: flex;
          align-items: center;
          cursor: pointer; }
          .header-container .profile .profile-settings .dropdown-wrap .dropdown .dropdown-list-item svg {
            margin-right: 10px;
            min-width: 15px;
            width: 15px; }
            .header-container .profile .profile-settings .dropdown-wrap .dropdown .dropdown-list-item svg path, .header-container .profile .profile-settings .dropdown-wrap .dropdown .dropdown-list-item svg line {
              stroke: #3A3F56; }
          .header-container .profile .profile-settings .dropdown-wrap .dropdown .dropdown-list-item p {
            font-size: 13px;
            margin-bottom: 0; }
          .header-container .profile .profile-settings .dropdown-wrap .dropdown .dropdown-list-item:hover {
            background-color: #f2f2f2; }
    .header-container .profile .profile-settings .settings-wrap svg path {
      stroke: #fff; }
    .header-container .profile .profile-settings .settings-wrap svg g {
      fill: transparent;
      stroke: #fff; }
    .header-container .profile .profile-settings .line::before {
      background-color: #fff; }
  .header-container .icon-box {
    background-color: #fff;
    border-radius: 6px;
    padding: 10px;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
    .header-container .icon-box svg {
      min-height: 21px;
      width: auto; }
    .header-container .icon-box .notification-number {
      position: absolute;
      top: 5px;
      right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #138DB9;
      font-size: 9px;
      font-weight: 600;
      color: #fff; }
      .header-container .icon-box .notification-number p {
        margin-top: 2px;
        margin-bottom: 0; }
  .header-container .ml-15 {
    margin-left: 15px; }

.banner-sidebar {
  margin-bottom: 20px; }
  .banner-sidebar img {
    width: 100%;
    height: auto;
    object-fit: cover; }

.first-banner-header {
  width: 30%;
  margin-left: 100px; }
  .first-banner-header img {
    width: 100%;
    height: 200px;
    object-fit: contain; }

.second-banner-header {
  margin-left: 100px;
  margin-top: 0px;
  width: 50%; }
  .second-banner-header img {
    width: 100%;
    height: 300px;
    object-fit: contain; }

.header-banner-wrap {
  position: absolute;
  top: 30px;
  left: 300px;
  display: flex;
  width: calc(100vw - 300px);
  z-index: 9; }

.left-table-mt .list-builder-filters-wrap {
  padding: 0 20px;
  padding-top: 15px;
  width: 100%;
  background-color: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px; }
  .left-table-mt .list-builder-filters-wrap .list-builder-filters {
    width: 100%; }
  .left-table-mt .list-builder-filters-wrap .field-strap-wrap:first-child {
    width: 40%; }
  .left-table-mt .list-builder-filters-wrap .field-strap-wrap:last-child {
    width: 60%; }

.right-table-mt {
  margin-top: -85px; }
  .right-table-mt .right-table-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 18px;
    min-height: 50px; }
    .right-table-mt .right-table-header button {
      background-color: #3A416F;
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      display: flex;
      align-items: center;
      border-radius: 6px;
      padding: 9px;
      min-width: 170px;
      z-index: 99; }
      .right-table-mt .right-table-header button .icon-box {
        margin-right: 17px;
        background-color: #fff;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px; }
        .right-table-mt .right-table-header button .icon-box svg {
          width: 17px;
          min-width: 18px; }

.section-table-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px; }
  .section-table-title h4 {
    color: #3A416F;
    font-weight: 700;
    font-size: 23px; }
  .section-table-title .right-table-header {
    z-index: 9; }
    .section-table-title .right-table-header button {
      background-color: #3A416F;
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      display: flex;
      align-items: center;
      border-radius: 6px;
      padding: 9px;
      min-width: 170px; }
      .section-table-title .right-table-header button .icon-box {
        margin-right: 17px;
        background-color: #fff;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px; }
        .section-table-title .right-table-header button .icon-box svg {
          width: 17px;
          min-width: 18px; }

.order-column-wrap {
  display: flex;
  align-items: center;
  position: relative; }
  .order-column-wrap input {
    width: 60px;
    height: 32px;
    font-size: 13px; }
  .order-column-wrap button {
    margin-left: 20px;
    background-color: #138DB9;
    border-radius: 6px;
    width: 36px;
    min-width: 36px;
    height: 36px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important; }
    .order-column-wrap button svg {
      max-height: 20px; }
      .order-column-wrap button svg circle, .order-column-wrap button svg path {
        stroke: #fff; }
  .order-column-wrap .success-added-loader {
    position: absolute;
    top: 0;
    right: -40px; }

.info-icon-wrap svg {
  width: 80px;
  min-width: 80px;
  height: auto; }
  .info-icon-wrap svg path {
    fill: #138DB9; }

.info-modal-footer button {
  background-color: #3A416F !important; }

.order-modal-wrap {
  width: 1300px; }
  .order-modal-wrap .modal-header {
    display: flex;
    justify-content: space-between;
    border: none !important;
    background-color: #DEEEFE !important; }
  .order-modal-wrap .modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #DEEEFE !important; }
  .order-modal-wrap .title {
    font-size: 27px;
    color: #3A416F;
    font-weight: 700; }
  .order-modal-wrap .print-button {
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: 50px;
    outline: none;
    border: none;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
    color: #3A416F;
    font-size: 13px;
    font-weight: 400; }
    .order-modal-wrap .print-button:hover {
      background-color: rgba(255, 255, 255, 0.8); }
    @media (min-width: 1200px) and (max-width: 1399px) {
      .order-modal-wrap .print-button {
        padding: 0 10px; } }
    .order-modal-wrap .print-button .svg-box {
      background-color: #138DB9;
      border-radius: 6px;
      padding: 5px 6px;
      margin-right: 10px; }

.order-items-modal-wrap {
  padding-bottom: 0 !important;
  background-color: #DEEEFE !important;
  border: none !important; }
  .order-items-modal-wrap td {
    text-align: left; }
  .order-items-modal-wrap th {
    text-align: left; }
  .order-items-modal-wrap .list-builder-wrap {
    position: relative;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%; }
    .order-items-modal-wrap .list-builder-wrap .table-container-scroll {
      overflow: unset; }
    .order-items-modal-wrap .list-builder-wrap::-webkit-scrollbar {
      width: 10px; }
    .order-items-modal-wrap .list-builder-wrap::-webkit-scrollbar-thumb {
      background-color: #818FA5;
      border-radius: 6px; }
  .order-items-modal-wrap .footer-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .order-items-modal-wrap .footer-wrap .status-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
      .order-items-modal-wrap .footer-wrap .status-wrap p {
        margin-bottom: 0;
        font-size: 13px;
        font-weight: 600; }
      .order-items-modal-wrap .footer-wrap .status-wrap .processed-ts {
        font-size: 12px;
        font-weight: 400; }

.active-order-row {
  background-color: #138db9; }
  .active-order-row td {
    color: #fff !important; }
    .active-order-row td button svg g, .active-order-row td button svg rect {
      fill: #fff !important; }
    .active-order-row td button svg path, .active-order-row td button svg line {
      stroke: #fff !important; }
    .active-order-row td button .edit-icon path, .active-order-row td button .edit-icon line {
      fill: #fff !important; }
  .active-order-row:hover {
    background-color: #138db9 !important; }
    .active-order-row:hover td {
      color: #fff !important; }

.list-builder-product-name-wrap h6 {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 3px; }

.list-builder-product-name-wrap p {
  font-size: 10px;
  font-weight: 600;
  color: #49B848;
  margin-bottom: 0; }

.finish-order-modal-wrap .finish-order-modal-body {
  padding-bottom: 0 !important; }
  .finish-order-modal-wrap .finish-order-modal-body .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .finish-order-modal-wrap .finish-order-modal-body .form-group label {
      font-size: 16px;
      font-weight: 600;
      color: #3A3F56; }
    .finish-order-modal-wrap .finish-order-modal-body .form-group .form-control {
      height: 150px; }

.finish-order-modal-wrap .modal-footer button {
  white-space: nowrap;
  width: auto; }
  .finish-order-modal-wrap .modal-footer button:last-child {
    background-color: #dc3545 !important; }

.note-wrap {
  display: flex;
  align-items: flex-start;
  margin-top: 0px;
  padding-left: 10px; }
  .note-wrap p {
    font-size: 14px;
    font-weight: 600;
    color: #3A3F56;
    margin-right: 10px; }
  .note-wrap span {
    font-size: 14px;
    color: #3A3F56;
    font-weight: 400;
    text-align: left; }

.notifications-header-wrap {
  position: relative;
  cursor: pointer; }
  .notifications-header-wrap .notifications-dropdown-wrap {
    cursor: default;
    position: absolute;
    top: 125%;
    right: -7px;
    padding: 12px 0;
    background-color: #fff;
    border-radius: 6px;
    min-width: 400px;
    max-width: 400px;
    width: auto;
    z-index: 999;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); }
    .notifications-header-wrap .notifications-dropdown-wrap .notifiction-dropdown-content {
      max-height: 50vh;
      overflow-y: auto; }
      .notifications-header-wrap .notifications-dropdown-wrap .notifiction-dropdown-content::-webkit-scrollbar {
        width: 5px; }
      .notifications-header-wrap .notifications-dropdown-wrap .notifiction-dropdown-content::-webkit-scrollbar-thumb {
        background-color: #818FA5;
        border-radius: 6px; }
    .notifications-header-wrap .notifications-dropdown-wrap:before {
      content: '';
      display: block;
      position: absolute;
      top: -5px;
      right: 20px;
      width: 12px;
      height: 12px;
      background-color: #fff;
      transform: rotate(46deg);
      z-index: 99; }
    .notifications-header-wrap .notifications-dropdown-wrap .notification-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 15px;
      border-bottom: 1px solid rgba(58, 63, 86, 0.2);
      padding-bottom: 10px; }
      .notifications-header-wrap .notifications-dropdown-wrap .notification-header h6 {
        left: 0;
        font-size: 17px;
        font-weight: 700; }
      .notifications-header-wrap .notifications-dropdown-wrap .notification-header .delete-notifications-div {
        font-size: 13px;
        font-weight: 400;
        text-decoration: underline;
        cursor: pointer; }
    .notifications-header-wrap .notifications-dropdown-wrap .notification-item {
      padding: 10px 15px;
      cursor: pointer;
      border-bottom: 1px solid rgba(58, 63, 86, 0.2); }
      .notifications-header-wrap .notifications-dropdown-wrap .notification-item:last-child {
        border-bottom: 0; }
      .notifications-header-wrap .notifications-dropdown-wrap .notification-item h6 {
        left: 0;
        font-size: 13px;
        font-weight: 600; }
      .notifications-header-wrap .notifications-dropdown-wrap .notification-item .notifcation-description {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px; }
        .notifications-header-wrap .notifications-dropdown-wrap .notification-item .notifcation-description p {
          font-size: 12px;
          margin-bottom: 0; }
        .notifications-header-wrap .notifications-dropdown-wrap .notification-item .notifcation-description .notif-date-time {
          font-size: 11px;
          text-align: right;
          margin-left: 10px; }
      .notifications-header-wrap .notifications-dropdown-wrap .notification-item:hover {
        background-color: #DEEEFE; }
    .notifications-header-wrap .notifications-dropdown-wrap .not-clicked {
      background-color: rgba(222, 238, 254, 0.8); }

.cursor-default {
  cursor: default !important; }

.print-helper-wrap {
  position: absolute; }

.print-helper-custom-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center; }
  .print-helper-custom-wrap .print-helper-custom {
    position: relative;
    width: calc(50vh*1.19);
    height: calc(50vh*1.684);
    background-color: #fff; }
  .print-helper-custom-wrap .close-print {
    position: absolute;
    font-size: 30px;
    color: #fff;
    top: 10px;
    right: 10px;
    cursor: pointer; }
